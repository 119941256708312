import _ from 'lodash';

import { isGroupNode } from '../../Nodes/nodes';
import {
  START_NODE_TYPE,
  isDisconnectedNode,
  isUnreachableNode,
} from '../utils';

import type { StoreSlice } from '.';

type NodeId = string;
export type NodesStateSlice = {
  nodeStates: {
    disconnected: Record<NodeId, boolean>;
    unreachable: Record<NodeId, boolean>;
    createStates: () => void;
    getState: (id: NodeId) => {
      disconnected: boolean;
      unreachable: boolean;
    };
    hasUnreachables: () => boolean;
  };
};

export const createNodesStateSlice: StoreSlice<NodesStateSlice> = (
  set,
  get
) => ({
  nodeStates: {
    disconnected: {},
    unreachable: {},
    createStates: () => {
      // get all node that are not inside a group

      const ungroupedNodes = get().nodes?.filter((node) => !isGroupNode(node));

      const g = get().intentGraph?.g;

      const states = ungroupedNodes?.reduce(
        (acc, node) => {
          if (node.id === START_NODE_TYPE) return acc;
          const disconnected = isDisconnectedNode(node.id, g);
          _.set(acc, `disconnected.${node.id}`, disconnected);
          _.set(
            acc,
            `unreachable.${node.id}`,
            !disconnected && isUnreachableNode(node.id, g)
          );

          return acc;
        },
        {
          disconnected: {} as Record<NodeId, boolean>,
          unreachable: {} as Record<NodeId, boolean>,
        }
      );

      set((state) => ({
        nodeStates: {
          ...state.nodeStates,
          ...states,
        },
      }));
    },
    getState: (id: NodeId) => {
      return {
        disconnected: get().nodeStates.disconnected[id],
        unreachable: get().nodeStates.unreachable[id],
      };
    },
    hasUnreachables: () => {
      return Object.values(get().nodeStates.unreachable).some(Boolean);
    },
  },
});
