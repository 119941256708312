import BigDecimal from 'bignumber.js';
import { etherToWei, weiToEther } from '~/lib/utils/number';

export const getValue = (
  value?: string,
  decimals: number = 18,
  convertor?: typeof weiToEther | typeof etherToWei
) => {
  if (!value) return undefined;
  if (!decimals) return value;
  if (!convertor) return value;
  return value === '' ? '' : convertor(value, decimals);
};

export const getWei = (value?: string, decimals?: number) => {
  return getValue(value, decimals, etherToWei);
};
export const getEthers = (value?: string, decimals?: number) =>
  getValue(value, decimals, weiToEther);

export const multiply = (
  value1?: string | number,
  value2?: string | number,
  decimals = 0
) => {
  BigDecimal.config({
    DECIMAL_PLACES: decimals,
    ROUNDING_MODE: BigDecimal.ROUND_HALF_UP,
  });

  if (!value1) return undefined;
  if (value2 === 1) return String(value1);
  const mul = new BigDecimal(value1)
    .multipliedBy(new BigDecimal(value2 || 0))
    .toFixed(decimals);

  return new BigDecimal(mul).multipliedBy('1').toString();
};

export const divide = (
  value1?: string | number,
  value2?: string | number,
  decimals = 0
) => {
  BigDecimal.config({
    DECIMAL_PLACES: decimals,
    ROUNDING_MODE: BigDecimal.ROUND_HALF_UP,
  });

  if (!value1) return undefined;
  if (!value2) return undefined;
  if (value2 === 1) return String(value1);
  const div = new BigDecimal(value1)
    .div(new BigDecimal(value2))
    .toFixed(decimals);

  return new BigDecimal(div).multipliedBy('1').toString();
};

export const isMax = (wei: string, maxWei: string) => {
  return new BigDecimal(wei).gte(new BigDecimal(maxWei));
};

export const isExceededMax = (wei: string, maxWei: string) => {
  return new BigDecimal(wei).gt(new BigDecimal(maxWei));
};

export const isExceededMin = (wei: string, minWei: string) => {
  return new BigDecimal(wei).lt(new BigDecimal(minWei));
};
