/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */

import { service } from '@kiroboio/fct-sdk';
import _ from 'lodash';

import type { PluginNodeData } from '../../Nodes/nodes';
import { isPluginNodeData } from '../../Nodes/nodes';
import {
  isTokens,
  type ChainlinkToken,
  type FCTOptionToken,
} from '../hooks/useCombineFctAndVaultTokens';

import { getChainId } from '~/lib/utils/main';
import { canBePlugin, isPluginNode, parseParameterPath } from '../utils';

import type { ChainIds } from '~/lib/wagmi';
import type { StoreSlice } from '.';
import type { FCTNode, PluginInstanceType } from '@kiroboio/fct-builder';

export type PluginsSlice = {
  plugins: Record<string, PluginInstanceType>;
  pluginTokens: Record<string, FCTOptionToken | ChainlinkToken>;
  getPluginForNode: (p: {
    id?: string;
    node?: FCTNode;
  }) => PluginInstanceType | undefined;
  getPluginNode: (p: { id?: string }) => FCTNode | undefined;
  setPlugins: () => void;
  getPluginsTokens: () => Record<string, FCTOptionToken | ChainlinkToken>;
  getPluginsToken: (
    address: string
  ) => FCTOptionToken | ChainlinkToken | undefined;
  getParameterOptions: (
    nodeId: string,
    path: string
  ) => PluginInstanceType['input']['paramsList'][number]['param']['options'];
};

const isEqualNodeToPlugin = (
  data: PluginNodeData,
  plugin: PluginInstanceType
) => {
  return (
    data.protocol === plugin.protocol &&
    data.method === plugin.method &&
    data.name === plugin.name
  );
};

export const createPluginsSlice: StoreSlice<PluginsSlice> = (set, get) => ({
  plugins: {},
  pluginTokens: {},
  getPluginForNode: ({ id, node }) => {
    if (!id) return;
    if (node && !isPluginNode(node)) return;
    return get().intentGraph?.getNode({ id })?.pluginInstance;
  },
  getPluginNode: ({ id }) => {
    if (!id) return;

    return get().intentGraph?.getNode({ id });
  },
  setPlugins: () => {
    const { nodes } = get();
    let shouldUpdate = false;
    const { plugins } = get();

    const chainId = getChainId(service.network.data.raw.netId) as ChainIds;
    try {
      const newPlugins = nodes?.reduce(
        (acc, { id, data }) => {
          const plugin = plugins[id];
          if (isPluginNodeData(data)) {
            if (plugin && isEqualNodeToPlugin(data, plugin)) return acc;
            const prevAcc = acc;
            canBePlugin({
              nodeData: data,
              chainId,
              walletAddress: service.wallet.data.raw.address,
              vaultAddress: service.vault.data.raw.address,
              onTrue: (p) => {
                prevAcc[id] = p.instance;
              },
            });
            shouldUpdate = true;
          }

          return acc;
        },
        {} as PluginsSlice['plugins']
      );

      const nPlugins = newPlugins;
      if (!shouldUpdate) return;
      set((state) => ({
        ...state,
        plugins: { ...plugins, ...nPlugins },
      }));
    } catch (e) {
      console.warn('plugins error', e);
    }
    set((state) => ({
      pluginTokens: get().getPluginsTokens(),
    }));
  },

  getPluginsToken: (address) => {
    if (!address || typeof address !== 'string') return;
    return get().pluginTokens[address.toLowerCase()];
  },

  getPluginsTokens: () => {
    return Object.values(get().plugins)
      .map((plugin) => plugin.input.paramsList)
      .flat()
      .reduce(
        (acc, { param }) => {
          if (isTokens(param.options)) {
            param.options.forEach((option) => {
              const address = option.value.toLowerCase();
              if (!acc[address]) acc[address] = option;
            });
          }
          return acc;
        },
        {} as Record<string, FCTOptionToken | ChainlinkToken>
      );
  },

  getParameterOptions: (nodeId, path) => {
    // const parsed = parseParameterPath(path);
    // if (!parsed) return;
    // const plugin = get().plugins[nodeId];
    // return _.get(
    //   plugin,
    //   [parsed.type, 'params', parsed.inputPath, 'options'].join('.')
    // );

    const { intentGraph } = get();
    if (!intentGraph) return [];
    return intentGraph.getParam({
      id: nodeId,
      handle: path,
      handleType: 'INPUT',
    })?.value.param.options;
  },
});
