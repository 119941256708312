/* eslint-disable react/destructuring-assignment */
import type { InputProps } from '@chakra-ui/react';
import type { Ref } from 'react';
import { forwardRef, useState } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import {
  NumericFormat,
  NumberFormatBase,
  useNumericFormat,
} from 'react-number-format';

import type {
  NumberInputType,
  UseNumberInputParams,
} from '~/lib/components/Input/Amount/useNumberInput';
import { useNumberInput } from '~/lib/components/Input/Amount/useNumberInput';
import { DynamicWidthInput } from '../DynamicWidthInput';
import type { SourceType } from 'react-number-format/types/types';
import { formatValue } from './utils';

interface NumberInputProps
  extends Omit<NumericFormatProps, keyof InputProps>,
    InputProps {
  isIntentMode?: boolean;
}

export interface HookedNumberInputProps<T extends NumberInputType = 'wei'>
  extends NumberInputProps {
  hookParams: UseNumberInputParams<T>;
  ioType?: 'INPUT' | 'OUTPUT';
}

const CustomNumberFormat = (
  props: NumericFormatProps & {
    isIntentMode?: boolean;
    ioType?: 'INPUT' | 'OUTPUT';
  }
) => {
  const { isIntentMode, ioType, decimalScale, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const {
    // format,
    removeFormatting,
    isCharacterSame,
    ...numProps
  } = useNumericFormat(props);

  if (isIntentMode) {
    const format = (inputValue: string) => {
      if (!inputValue) return '';
      if (numProps.value && !rest.value) return '';

      return formatValue({
        value: inputValue,
        decimalScale: props.decimalScale || 0,
        ioType,
        isFocused,
      });
    };

    return (
      <NumberFormatBase
        {...numProps}
        title={typeof props.value === 'string' ? props.value : undefined}
        format={format}
        removeFormatting={removeFormatting}
        onFocus={(e) => {
          setIsFocused(true);
          if (numProps.onFocus) numProps.onFocus(e);
          if (!props.value) return;
          if (props.value === '0') return;
          numProps.onValueChange?.(
            {
              formattedValue: props.value as string,
              value: props.value as string,
              floatValue: 0,
            },
            { event: undefined, source: 'prop' as SourceType }
          );
        }}
        onBlur={(e) => {
          setIsFocused(false);
          if (numProps.onBlur) numProps.onBlur(e);
        }}
      />
    );
  }

  return (
    <NumericFormat
      key={numProps.value && !rest.value ? 'clear_value' : ''}
      {...rest}
    />
  );
};
export const NumberInput = forwardRef<
  HTMLInputElement,
  Omit<NumberInputProps, 'input'>
>(({ isIntentMode, ...rest }, ref) => {
  return (
    <DynamicWidthInput
      ref={ref}
      as={CustomNumberFormat}
      rounded="full"
      _placeholder={{ color: 'gray.300' }}
      {...rest}
      // getInputRef={ref}
      isDynamicWidth={Boolean(isIntentMode)}
    />
  );
});

const HookedNumberInputRef = forwardRef(
  <T extends NumberInputType = 'wei'>(
    { hookParams, ...props }: HookedNumberInputProps<T>,
    ref: Ref<HTMLInputElement>
  ) => {
    const { controller } = useNumberInput(hookParams);
    return <NumberInput ref={ref} {...controller} {...props} />;
  }
);

// forwardRef with generic | https://dirask.com/posts/React-forwardRef-with-generic-component-in-TypeScript-D6BoRD
export const HookedNumberInput = HookedNumberInputRef as <
  T extends NumberInputType = 'wei',
>(
  props: HookedNumberInputProps<T> & { ref?: Ref<HTMLInputElement> }
) => JSX.Element;
