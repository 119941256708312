/* eslint-disable sonarjs/cognitive-complexity */
import type { ParamJSONData, ParamsSchemas } from '@kiroboio/fct-core';
import { isVariable } from '@kiroboio/fct-core';
import { service } from '@kiroboio/fct-sdk';
import _ from 'lodash';
import type { StoreSlice } from '.';
import { isPluginNodeData } from '../../Nodes/nodes';
import {
  createInputParameterPath,
  createOutputParameterPath,
} from '../hooks/mapper/utils';
import type { ParameterValue } from './pointers';
import { type PluginNodeData, UILabels } from '@kiroboio/fct-builder';

export type InputSlice = {
  parametersMap: Record<string, string>;
  parameterPathMap: Record<string, string>;
  getParameter: (nodeId: string, path: string) => ParamsSchemas | undefined;
  getParameters: (nodeId: string, type: 'input' | 'output') => ParamJSONData[];
  getAccountInputValue: (nodeId: string) => ParameterValue;
  /** use `store.pointerSlice.setValue` instead */
  setParameterValue: (
    nodeId: string,
    path: string,
    value: ParameterValue
  ) => ParameterValue | undefined;
  setParameterDecimalValue: (
    nodeId: string,
    path: string,
    decimals: string
  ) => void;
  setParameterEstimatedValue: (
    nodeId: string,
    path: string,
    value?: string
  ) => void;
  createParametersMap: () => void;
  updateParametersMap: (id: string) => void;
};

export const createInputSlice: StoreSlice<InputSlice> = (set, get) => ({
  parametersMap: {},
  parameterPathMap: {},
  getParameter: (nodeId, path) => {
    const param = get().intentGraph?.getParam({
      id: nodeId,
      handle: path,
      handleType: path.startsWith(UILabels.OUTPUT) ? 'OUTPUT' : 'INPUT',
    });
    return param?.value.param;
  },

  getParameters: (nodeId, type) => {
    return get().getNodeData<PluginNodeData>(nodeId)?.[`${type}List`] ?? [];
  },

  getAccountInputValue: (nodeId) => {
    const parameter = get()
      .getParameters(nodeId, 'input')
      .find((param) => {
        const parameterData = get().intentGraph?.getParam({
          id: nodeId,
          handle: param.key,
          handleType: 'INPUT',
        });
        return parameterData?.value.jsonParam?.uiType === 'account';
      });

    if (parameter) {
      return get().pointersSlice.getValue(
        nodeId,
        createInputParameterPath(parameter.key)
      );
    }
    return service.vault.data.raw.address;
  },
  updateParametersMap: (id) => {
    const node = get().nodes.find((n) => n.id === id);
    if (!node) return;

    set((state) => {
      if (!isPluginNodeData(node.data)) return state;
      const newState = { ...state };
      node.data.inputList.forEach((input, i) => {
        const path = createInputParameterPath(input.key);
        newState.parametersMap[`${node.id}.${path}`] = `inputList.${i}`;
        newState.parameterPathMap[input.key] = path;
      });

      node.data.outputList.forEach((output, i) => {
        const path = createOutputParameterPath(output.key);
        newState.parametersMap[`${node.id}.${path}`] = `outputList.${i}`;
        newState.parameterPathMap[output.key] = path;
      });

      return newState;
    });
  },

  createParametersMap: () => {
    const maps = get().nodes?.reduce(
      (acc, node) => {
        if (!isPluginNodeData(node.data)) return acc;

        node.data.inputList.forEach((input, i) => {
          const path = createInputParameterPath(input.key);
          acc.parametersMap[`${node.id}.${path}`] = `inputList.${i}`;
          acc.parameterPathMap[input.key] = path;
        });

        node.data.outputList.forEach((output, i) => {
          const path = createOutputParameterPath(output.key);
          acc.parametersMap[`${node.id}.${path}`] = `outputList.${i}`;
          acc.parameterPathMap[output.key] = path;
        });

        return acc;
      },
      { parametersMap: {}, parameterPathMap: {} } as Pick<
        InputSlice,
        'parametersMap' | 'parameterPathMap'
      >
    );

    set({ ...maps });
  },

  setParameterValue: (nodeId, path, value) => {
    const listPath = get().parametersMap[`${nodeId}.${path}`];
    const input = get().getParameter(nodeId, path);
    const data = get().getNodeData(nodeId) as PluginNodeData;

    const pluginParam = path.includes('inputWithMeta')
      ? get()
          .getPluginForNode({ id: nodeId })
          ?.input.paramsList.find((param) => {
            return param.key === path.replace('inputWithMeta.', '');
          })
      : undefined;
    if (!input || !data) return value;

    if (pluginParam) {
      if (typeof value === 'string') {
        try {
          if (isVariable(JSON.parse(value))) {
            pluginParam.param.setString({ value: JSON.parse(value) });
          } else {
            pluginParam.param.setString({ value });
          }
        } catch (e) {
          pluginParam.param.setString({ value });
        }
      } else {
        pluginParam.param.setString({ value });
      }
    }
    let parsedValue = value;

    if (
      pluginParam?.param.value &&
      typeof pluginParam.param.value === 'string'
    ) {
      parsedValue = pluginParam.param.value;
    }

    if (
      pluginParam?.param.value &&
      (typeof pluginParam.param.value === 'number' ||
        typeof pluginParam.param.value === 'boolean')
    ) {
      parsedValue = `${pluginParam.param.value}`;
    }

    try {
      if (
        pluginParam?.param.value &&
        typeof pluginParam.param.value === 'object' &&
        !isVariable(pluginParam.param.value)
      ) {
        parsedValue = JSON.stringify(pluginParam.param.value);
      }
    } catch (e) {
      console.warn(`${pluginParam?.key} error ${e}`);
    }

    const newData = _.set(data, listPath, {
      ...input,
      value: parsedValue,
    });

    get().setNodeData(nodeId, newData);
    return parsedValue;
  },

  setParameterDecimalValue: (nodeId, path, decimals) => {
    const listPath = get().parametersMap[`${nodeId}.${path}`];
    const input = get().getParameter(nodeId, path);
    const data = get().getNodeData(nodeId) as PluginNodeData;
    if (!input || !data) return;
    const newData = _.set(data, listPath, {
      ...input,
      decimals: Number(decimals || 0),
    });
    get().setNodeData(nodeId, newData);
    // get().instructionsSlice.createInstructions();
  },

  setParameterEstimatedValue: (nodeId, path, value) => {
    const listPath = get().parametersMap[`${nodeId}.${path}`];
    const input = get().getParameter(nodeId, path);
    const data = get().getNodeData(nodeId) as PluginNodeData;
    if (!input || !data) return;

    const newData = _.set(data, listPath, {
      ...input,
      estimatedValue: value,
    });

    get().setNodeData(nodeId, newData);
  },
});
