/* eslint-disable react-hooks/exhaustive-deps */
import type { InputProps } from '@chakra-ui/react';
import { useForceUpdate } from '@react-spring/shared';
import { useEffect, useState } from 'react';
import type { NumericFormatProps } from 'react-number-format';

import { usePrevious } from 'react-use';
import { weiToEther } from '~/lib/utils/number';
import { divide, getWei, isExceededMax, isMax } from './utils';

type OnValueChange = NumericFormatProps['onValueChange'];
export type NumberInputType = 'wei' | 'usd';
export type Required<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type Params<T extends NumberInputType> = {
  type: T;
  value: string;
  decimals?: number;
  usdRate?: number;
  onChange?: (value?: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMaxSet?: () => void;
  min?: string;
  max?: string;
  limitToMax?: boolean;
};

export type UseNumberInputParams<T extends NumberInputType> = T extends 'wei'
  ? Params<T>
  : Required<Params<T>, 'usdRate'>;

export const useNumberInput = <T extends NumberInputType>({
  // type = 'usd',
  value,
  decimals = 0,
  usdRate = 1,
  onMaxSet,
  onChange,
  onFocus,
  onBlur,
  max,
  limitToMax,
}: UseNumberInputParams<T>) => {
  const update = useForceUpdate();
  const [focused, setFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState<string | undefined>(
    weiToEther(value, decimals)
  );

  const emitChange = (val?: string) => {
    return onChange?.(val);
  };

  useEffect(() => {
    const div = divide(displayValue, usdRate, decimals);
    const wei = getWei(div, decimals);
    const prevValue = wei;

    if (value !== prevValue) {
      emitChange(value);
      setDisplayValue(weiToEther(value, decimals));
    }
  }, [value]);

  useEffect(() => {
    const weiValue = getWei(displayValue, decimals);
    emitChange(weiValue);
  }, [decimals]);

  const handleChange: OnValueChange = ({ value: dispValue }, sourceInfo) => {
    if (dispValue && dispValue[dispValue.length - 1] === '.') return;
    const div = divide(dispValue, usdRate, decimals);
    const wei = getWei(div, decimals);

    if (limitToMax && max && wei && isExceededMax(wei, max)) {
      emitChange(max);
      onMaxSet?.();
      update();
      return;
    }

    if (sourceInfo?.source === 'prop') return;
    emitChange(wei);
    setDisplayValue(dispValue);
  };

  const prevUsdRate = usePrevious(usdRate);
  useEffect(() => {
    if (!prevUsdRate) return;
    if (prevUsdRate === usdRate) return;
    onChange?.(value);
  }, [usdRate, prevUsdRate]);

  const inputController: NumericFormatProps &
    InputProps & { isNumericString?: boolean } = {
    value: displayValue,
    isNumericString: true,
    onValueChange: handleChange,
    onFocus: () => {
      setFocused(true);
      onFocus?.();
    },
    onBlur: () => {
      setFocused(false);
      onBlur?.();
    },
    decimalScale: 18,
    thousandSeparator: true,
  };

  return {
    controller: inputController,
    isMax: max && isMax(value, max),
    isExceededMax: max && isExceededMax(value, max),
    focused,
  };
};
