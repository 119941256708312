import type { ReactNode } from 'react';
import React from 'react';

import type { ChainIds } from '../wagmi';

export const tryCatch = <T, E = Error>(
  fn: () => T,
  onError: (error: E) => T
): T => {
  try {
    return fn();
  } catch (error) {
    if (typeof onError === 'function') {
      return onError(error as E);
    }
    return onError as T;
  }
};

export const isReactNode = (value: any): value is ReactNode => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    React.isValidElement(value)
  );
};

export const getChainName = (chainId: ChainIds) => {
  if (chainId === '1') return 'mainnet';
  if (chainId === '10') return 'optimism';
  if (chainId === '8453') return 'base';
  if (chainId === '42161') return 'arbitrum_one';
  if (chainId === '11155111') return 'sepolia';
  return 'mainnet';
};

export const getChainId = (chainNameOrId: string): ChainIds | '0' => {
  if (
    chainNameOrId === '1' ||
    chainNameOrId === 'mainnet' ||
    chainNameOrId === 'main'
  )
    return '1';
  if (chainNameOrId === '10' || chainNameOrId === 'optimism') return '10';
  if (chainNameOrId === '8453' || chainNameOrId === 'base') return '8453';
  if (chainNameOrId === '42161' || chainNameOrId === 'arbitrum_one')
    return '42161';
  if (chainNameOrId === '11155111' || chainNameOrId === 'sepolia')
    return '11155111';
  return '0';
};

export const isURL = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
