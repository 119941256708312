/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import { ValidationError } from 'yup';

import { yupValidators } from '../../Inputs/yupValidators';
import type { GroupNodeData } from '../../Nodes/nodes';
import { isPluginNodeData } from '../../Nodes/nodes';
import { createInputParameterPath } from '../hooks/mapper/utils';

import type {
  FCTNode,
  PluginNodeData,
  UIParamJSONData,
} from '@kiroboio/fct-builder';
import { UILabels } from '@kiroboio/fct-builder';
import {
  isExceededMax,
  isExceededMin,
} from '~/lib/components/Input/Amount/useNumberInput/utils';
import { weiToEther } from '~/lib/utils/number';
import type { StoreSlice } from '.';

export type ValidatorSlice = {
  validatorSlice: {
    errors: Record<string, Record<string, ValidationError>>;
    hasErrors: () => boolean;
    validate: (nodeId: string, path: string, isApp?: boolean) => boolean;
    validateCustom: (p: {
      nodeId: string;
      path: string;
      value: unknown;
      instructions?: UIParamJSONData;
    }) => boolean;
    validateNode: (nodeId: string, isApp?: boolean) => boolean;
    validateGroup: (groupId: string, isApp?: boolean) => boolean;
    validateAll: () => boolean;
    setError: (nodeId: string, path: string, error: ValidationError) => void;
    clearError: (nodeId: string, path: string) => void;
    getError: (nodeId: string, path: string) => ValidationError | void;
    getNodeErrorsCount: (nodeId: string) => number;
  };
};

export const createValidatorSlice: StoreSlice<ValidatorSlice> = (set, get) => ({
  validatorSlice: {
    errors: {},

    hasErrors: () => {
      const nodes = get().nodes.filter((node) =>
        isPluginNodeData(node.data)
      ) as FCTNode<PluginNodeData>[];

      for (const node of nodes) {
        for (const input of node.data.inputList) {
          if (
            get().validatorSlice.getError(
              node.id,
              createInputParameterPath(input.key)
            )
          ) {
            return true;
          }
        }
      }

      return false;
    },

    getError: (nodeId, path) => {
      const isPointed = get().pointersSlice.isPointed(nodeId, path);
      if (isPointed) return;
      return _.get(get().validatorSlice.errors, [nodeId, path]);
    },

    getNodeErrorsCount: (nodeId) => {
      const nodeInputs = get().getNodeData<PluginNodeData>(nodeId)?.inputList;
      if (!nodeInputs) return 0;
      return nodeInputs.reduce((acc, input) => {
        const error = get().validatorSlice.getError(
          nodeId,
          createInputParameterPath(input.key)
        );
        if (error) return acc + 1;
        return acc;
      }, 0);
    },

    setError: (nodeId, path, error) => {
      set((state) => ({
        validatorSlice: {
          ...state.validatorSlice,
          errors: _.merge({}, state.validatorSlice.errors, {
            [nodeId]: {
              [path]: error,
            },
          }),
        },
      }));
    },

    clearError: (nodeId, path) => {
      set((state) => {
        const { errors } = state.validatorSlice;
        const node = _.omit(state.validatorSlice.errors[nodeId], path);
        return {
          validatorSlice: {
            ...state.validatorSlice,
            errors: {
              ...errors,
              [nodeId]: node,
            },
          },
        };
      });
    },
    validateNode: (nodeId, isApp) => {
      const node = get().getNode(nodeId) as FCTNode<PluginNodeData>;
      let isValid: boolean = true;
      node.data?.inputList?.forEach((input: any) => {
        const path = createInputParameterPath(input.key);
        const isValidNode = get().validatorSlice.validate(
          node.id,
          path,
          isApp && node.data.protocol !== 'FUNCTIONS'
        );
        if (isValid) isValid = isValidNode;
      });

      return isValid;
    },
    validateGroup: (groupId, isApp) => {
      const groupData = get().getNodeData(groupId) as GroupNodeData;

      const nodes = groupData.nodeIds;

      if (!nodes.length) return true;
      const errors = nodes
        .map((node) => {
          return get().validatorSlice.validateNode(node, isApp);
        })
        .filter((isValid) => !isValid);

      return !errors.length;
    },
    validateAll: () => {
      const nodes = get().nodes.filter(
        (node) =>
          isPluginNodeData(node.data) &&
          get().intentGraph?.isNode(node.id).reachable
      ) as FCTNode<PluginNodeData>[];

      if (!nodes.length) return false;
      const errorsLength = nodes?.reduce((acc, node) => {
        let nodeErrors = 0;
        node.data.inputList.forEach((input: any) => {
          const path = createInputParameterPath(input.key);
          const isValid = get().validatorSlice.validate(node.id, path);
          if (!isValid) nodeErrors += 1;
        });
        return acc + nodeErrors;
      }, 0);

      return errorsLength === 0;
    },

    validateCustom: ({
      nodeId,
      path,
      value,
      instructions,
    }: {
      nodeId: string;
      path: string;
      value: unknown;
      instructions?: UIParamJSONData;
    }) => {
      if (
        instructions?.min &&
        typeof value === 'string' &&
        isExceededMin(value, instructions.min)
      ) {
        const displayErrorAmount = instructions.decimals
          ? weiToEther(instructions.min, Number(instructions.decimals))
          : value;

        const errorMix = new ValidationError(
          `value should be greater or equal ${displayErrorAmount}`,
          value,
          path
        );
        get().validatorSlice.setError(nodeId, path, errorMix);
        return false;
      }

      if (
        instructions?.max &&
        typeof value === 'string' &&
        isExceededMax(value, instructions.max)
      ) {
        const displayErrorAmount = instructions.decimals
          ? weiToEther(instructions.max, Number(instructions.decimals))
          : value;

        const errorMax = new ValidationError(
          `value should be less or equal ${displayErrorAmount}`,
          value,
          path
        );
        get().validatorSlice.setError(nodeId, path, errorMax);
        return false;
      }

      // if (typeof value === 'string' && instructions?.uiType === 'account') {
      //   const vaultAddress = service.vault.data.raw.address;

      //   console.log({ vaultAddress, value })
      //   if (vaultAddress?.toLowerCase() !== value?.toLowerCase()) return true;
      //   const errorWalletAddress = new ValidationError(
      //     `address should be a plain wallet address`,
      //     value,
      //     path
      //   );
      //   get().validatorSlice.setError(nodeId, path, errorWalletAddress);
      //   return false;
      // }

      return true;
    },
    validate: (nodeId, path, isApp) => {
      const intentParam = get().intentGraph?.getParam({
        id: nodeId,
        handle: path,
        handleType: path.includes(UILabels.INPUT) ? 'INPUT' : 'OUTPUT',
      })?.value;

      if (!intentParam) return true;
      const param = intentParam?.param;
      const instructions = intentParam?.jsonParam;
      if (param) {
        const isValidParam = param?.isValid();
        if (param.value && isValidParam.state === false) {
          const err = new ValidationError(
            isValidParam.error || `invalid value`,
            param.value,
            path
          );
          get().validatorSlice.setError(nodeId, path, err as ValidationError);
          return false;
        }
      }
      const isPointed = instructions?.isPointed;
      const { value } = param;

      if (instructions?.required === false && !value) return true;
      if (isApp && instructions?.required === true && !value && !isPointed) {
        const err = new ValidationError(`param is required`, value, path);
        get().validatorSlice.setError(nodeId, path, err as ValidationError);
        return false;
      }
      // if (!instructions?.shouldValidate) return true;

      if (
        !param ||
        !param.appType ||
        isPointed ||
        (param.value && !_.isString(param.value))
      ) {
        get().validatorSlice.clearError(nodeId, path);
        return true;
      }

      const validator =
        yupValidators[param.appType as keyof typeof yupValidators];

      try {
        validator?.validateSync(value);

        get().validatorSlice.clearError(nodeId, path);
        return get().validatorSlice.validateCustom({
          nodeId,
          path,
          value,
          instructions,
        });
      } catch (err) {
        get().validatorSlice.setError(nodeId, path, err as ValidationError);
        return false;
      }
    },
  },
});
