/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/prefer-single-boolean-return */
import type { ParamJSONData } from '@kiroboio/fct-core';
import _ from 'lodash';
import type {
  CustomizedGroupNodeParameter,
  FCTGroupIOMarkerNode,
  FCTGroupNode,
  FCTPluginNode,
  GroupNodeData,
} from '../../Nodes/nodes';
import {
  isGroupIOMarkerNode,
  isGroupNode,
  isGroupNodeData,
  isPluginNode,
} from '../../Nodes/nodes';
import { createGroupIOMarkerId, isGroupId } from '../../Nodes/utils/createNode';
import {
  HandleID,
  createEdgeFromConnection,
  createGraphLegacy,
  createStartNode,
} from '../utils';

import type { StoreSlice } from '.';
import { isMultiCallInputKey, isMultiCallNode } from '../SupportedPlugins';
import type {
  Edge,
  FctEdge,
  FCTNode,
  PluginNodeData,
} from '@kiroboio/fct-builder';

export type GroupParameter = { nodeId: string; parameter: ParamJSONData };
type GroupId = string;
type NodeId = string;

export type MarketplaceAppGroupData = {
  builder: {
    nodes: FCTNode[];
    edges: FctEdge[];
  };
  groupNode: FCTNode;
};

type GroupDetails = {
  in?: {
    nodeId: NodeId;
  };
  out: NodeId[];
  sinks: NodeId[];
  error?: { title: string; description: string };
  erroredNodes?: NodeId[];
};

// type CustomizedParams = { nodeId: string,
//   handleId: string,
//   data: Partial<CustomizedGroupNodeParameter>
// }[]

export type CustomizedParams = {
  [key: string]: { [key: string]: CustomizedGroupNodeParameter };
};
export type GroupSlice = {
  selectedGroup: string;
  setSelectedGroup: (groupId: string | undefined) => void;

  isMultiInput: (target: string, targetHandle: string) => boolean;

  getGroupChildren: (groupId: string) => {
    plugins: FCTPluginNode[];
    all: FCTNode[];
    groups: FCTGroupNode[];
    markers: FCTGroupIOMarkerNode[];
  };
  unmarkAsGroupEndNode: (nodeId: string) => void;
  markAsGroupStartNode: (nodeId: string) => void;
  markAsGroupEndNode: (nodeId: string) => void;
  getNestedNodesForGroup: (groupId: string) => FCTNode[];
  getAllElementsForGroup: (groupId: string) => MarketplaceAppGroupData;
  getGroupAndSubgroupNodeIds: (groupId: GroupId) => string[];
  getGroupMarkers: (groupId: string) => {
    inputMarkerNode: FCTGroupIOMarkerNode | undefined;
    outputMarkerNode: FCTGroupIOMarkerNode | undefined;
  };
  setCustomizedGroupParameter: (
    groupId: string,
    nodeId: string,
    handleId: string,
    data: Partial<CustomizedGroupNodeParameter>
  ) => void;

  setCustomizedGroupParameters: (groupId: string, c: CustomizedParams) => void;

  getCustomizedGroupParameter: (
    groupId: string,
    nodeId: string,
    handleId: string
  ) => CustomizedGroupNodeParameter | undefined;

  groupedNodeIds: Record<NodeId, GroupId>;
  createGroupedNodeIds: () => void;
  isNodeInsideGroup: (nodeId: NodeId) => boolean;
  getParentGroupIdForNode: (nodeId: NodeId) => GroupId | undefined;

  groupDetails: Record<GroupId, GroupDetails>;
  getGroupDetails: (groupId: GroupId) => GroupDetails | undefined;
  generateGroupDetails: (groupId: GroupId) => GroupDetails | undefined;
  createGroupDetails: () => void;
  isMarkedAsGroupStartNode: (nodeId: NodeId) => boolean;
  isMarkedAsGroupEndNode: (nodeId: NodeId) => boolean;
  isGroupStartNode: (nodeId: NodeId, groupId: string) => boolean;
  isGroupEndNode: (nodeId: NodeId) => boolean;
  getGroupLastNodes: (nodeIds: NodeId[]) => NodeId[];
  getGroupNodesMarkedAsLastNode: (nodeIds: NodeId[]) => NodeId[];
  isNodeInsideGroupAndHasConnectionError: (nodeId: NodeId) => boolean;
  getGroupErrorsCount: (groupId: GroupId) => number;
};

// const getGroupError = (startNodesCount: number): GroupDetails['error'] => {
//   if (startNodesCount > 1)
//     return {
//       title: `Group should have one starting node`,
//       description:
//         'It seems your group has more than one starting node. You can fix this by choosing one node as the initial point and making others follow.',
//     };

//   return undefined;
// };

export const createGroupSlice: StoreSlice<GroupSlice> = (set, get) => ({
  selectedGroup: '',
  setSelectedGroup: (groupId) => set({ selectedGroup: groupId }),

  isMultiInput: (target, targetHandle) => {
    const node = get().getNode(target);
    if (!node || !isMultiCallNode(node)) return false;
    return isMultiCallInputKey(targetHandle);
  },

  getGroupChildren: (groupId) => {
    const empty = { all: [], groups: [], plugins: [], markers: [] };

    const groupNode = get().getNode(groupId);
    if (!groupId || !isGroupNode(groupNode)) return empty;

    const ids = get().getNodeData<GroupNodeData>(groupId)?.nodeIds;
    if (!ids) return empty;

    const markers = get().nodes.filter(
      (node) => node.id.includes(groupId) && isGroupIOMarkerNode(node)
    ) as FCTGroupIOMarkerNode[];

    const separated = ids.reduce(
      (acc, id) => {
        const node = get().getNode(id);
        if (!node) return acc;
        acc.all.push(node);

        if (isGroupNode(node)) acc.groups.push(node);
        if (isPluginNode(node)) acc.plugins.push(node);
        return acc;
      },
      {
        all: [],
        groups: [],
        plugins: [],
      } as {
        plugins: FCTPluginNode[];
        all: FCTNode[];
        groups: FCTGroupNode[];
      }
    );

    return {
      ...separated,
      markers,
    };
  },

  getNestedNodesForGroup: (groupId = get().selectedGroup) => {
    const groupNode = get().getNode(groupId);
    if (!groupId || !isGroupNode(groupNode)) return [];

    const { groups, markers, plugins } = get().getGroupChildren(groupId);

    return [
      groupNode,
      ...markers,
      ...plugins,
      ...groups.map((g) => get().getNestedNodesForGroup(g.id)).flat(),
    ] as FCTNode[];
  },

  getAllElementsForGroup: (groupId = get().selectedGroup) => {
    const groupDetails = get().getGroupDetails(groupId);
    const groupNode = get().getNode(groupId);
    if (!groupDetails?.in?.nodeId || !groupNode) {
      throw new Error('Group does not have an input node');
    }

    const nodes = get().getNestedNodesForGroup(groupId);
    const edges = get().edges.filter((e) => {
      return nodes.some(
        (n) =>
          (n.id === e.source && !groupDetails?.out.includes(e.source)) ||
          (n.id === e.target && e.target !== groupDetails?.in?.nodeId)
      );
    });

    const startNode = createStartNode();

    const startToGroupFirstNodeEdge = createEdgeFromConnection({
      source: startNode.id,
      sourceHandle: HandleID.Success,
      target: groupDetails?.in?.nodeId,
      targetHandle: HandleID.In,
    });

    return {
      builder: {
        nodes: [startNode, ...nodes],
        edges: [startToGroupFirstNodeEdge, ...edges],
      },
      groupNode,
    };
  },

  setCustomizedGroupParameter: (groupId, nodeId, handleId, data) => {
    const group = get().getNodeData<GroupNodeData>(groupId);
    get().setNodeData(groupId, {
      ...group,
      customizedParameters: _.merge(group?.customizedParameters, {
        [nodeId]: {
          [handleId]: data,
        },
      }),
    });
  },

  setCustomizedGroupParameters: (groupId, customizedParams) => {
    const group = get().getNodeData<GroupNodeData>(groupId);
    get().setNodeData(groupId, {
      ...group,
      customizedParameters: {
        ...group?.customizedParameters,
        ...customizedParams,
      },
    });
  },

  getCustomizedGroupParameter: (groupId, nodeId, handleId) => {
    const { intentGraph } = get();
    return intentGraph?.getCustomizedParam({
      groupId,
      id: nodeId,
      handle: handleId,
    });
  },
  getGroupAndSubgroupNodeIds: (groupId) => {
    const group = get().getNodeData<GroupNodeData>(groupId);
    if (!group || !isGroupNodeData(group)) return [];

    return group.nodeIds.reduce((acc, id) => {
      if (isGroupId(id)) {
        return [...acc, ...get().getGroupAndSubgroupNodeIds(id)];
      }
      return [...acc, id];
    }, [] as string[]);
  },

  getGroupMarkers: (groupId) => {
    return {
      inputMarkerNode: get().getNode(createGroupIOMarkerId(groupId, 'input')),
      outputMarkerNode: get().getNode(createGroupIOMarkerId(groupId, 'output')),
    };
  },

  groupedNodeIds: {},
  createGroupedNodeIds: () => {
    const groupedNodes = get().nodes?.reduce(
      (map, group) => {
        if (isGroupNode(group)) {
          group.data.nodeIds.forEach((nodeId) => _.set(map, nodeId, group.id));
        }
        return map;
      },
      {} as Record<NodeId, GroupId>
    );
    set({ groupedNodeIds: groupedNodes });
  },
  isNodeInsideGroup: (nodeId) => {
    return Boolean(get().getParentGroupIdForNode(nodeId));
  },
  getParentGroupIdForNode: (nodeId) => {
    return get().intentGraph?.nodesGroup.get(nodeId);
  },

  groupDetails: {},
  getGroupDetails: (groupId) => {
    return get().groupDetails[groupId];
  },

  generateGroupDetails: (groupId) => {
    const { intentGraph } = get();
    if (!intentGraph) return;

    const group = get().getNodeData<GroupNodeData>(groupId);
    if (!group || !isGroupNodeData(group)) return;

    const groupNodeIds = intentGraph.getGroupNodeIds(groupId);
    const reachableGroupIds = groupNodeIds.filter((nodeId) => {
      const nodeData = intentGraph.getNode({ id: nodeId })
        ?.data as PluginNodeData;
      if (!nodeData) return false;
      if (nodeData.method === 'reflow') return true;
      if (nodeData._flowType === 'OFFCHAIN_ONLY') return false;
      if (intentGraph.isNode(nodeId).leaf) return false;

      return true;
    });

    const groupEdges = intentGraph.g
      .edges()
      .map((e) => {
        const edge = intentGraph.getEdge(e);
        if (
          edge &&
          reachableGroupIds.includes(edge.source) &&
          reachableGroupIds.includes(edge.target)
        ) {
          return edge;
        }
        return undefined;
      })
      .filter(Boolean) as Edge[];

    const g = createGraphLegacy(reachableGroupIds, groupEdges);
    const out = Array.from(new Set(group.out || [])) as string[];
    const sinks = Array.from(new Set(out.concat(g.sinks())));
    const sources = g.sources();
    const sourceId = group?.in?.nodeId || sources[0];

    return {
      in: { nodeId: sourceId },
      out,
      sinks,
      error: undefined, // getGroupError(sources.length),
      erroredNodes: sources.length > 1 ? sources : undefined,
    };
  },

  createGroupDetails: () => {
    const { nodes } = get();
    if (!get().intentGraph || !nodes) return;
    const groupDetails = nodes.reduce(
      (acc, group) => {
        if (!isGroupNode(group)) return acc;
        _.set(acc, group.id, get().generateGroupDetails(group.id));
        return acc;
      },
      {} as GroupSlice['groupDetails']
    );

    set({ groupDetails });
  },
  markAsGroupEndNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return;

    if (!get().groupDetails[groupId]) return;

    get().groupDetails[groupId].out = Array.from(
      new Set([...get().groupDetails[groupId].out, nodeId])
    );
    set({ groupDetails: get().groupDetails });
    get().setNodeData(groupId, { out: get().groupDetails[groupId].out });
  },

  markAsGroupStartNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return;

    if (!get().groupDetails[groupId]) return;

    get().groupDetails[groupId].in = { nodeId };
    set({ groupDetails: get().groupDetails });
    get().setNodeData(groupId, { in: { nodeId } } as Partial<GroupNodeData>);
  },

  unmarkAsGroupEndNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return;

    if (!get().groupDetails[groupId]) return;

    get().groupDetails[groupId].out = get().groupDetails[groupId].out.filter(
      (id) => id !== nodeId
    );
    set({ groupDetails: get().groupDetails });
    get().setNodeData(groupId, { out: get().groupDetails[groupId].out });
  },
  isMarkedAsGroupStartNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return false;
    return get().groupDetails[groupId]?.in?.nodeId === nodeId;
  },
  isMarkedAsGroupEndNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return false;
    return get().groupDetails[groupId]?.out.includes(nodeId);
  },
  isGroupStartNode: (nodeId, groupId) => {
    // const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return false;
    return get().groupDetails[groupId]?.in?.nodeId === nodeId;
  },
  isGroupEndNode: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return false;
    return get().groupDetails[groupId]?.sinks.includes(nodeId);
  },
  getGroupLastNodes: (nodeIds) => {
    return nodeIds.filter((id) => get().isGroupEndNode(id));
  },
  getGroupNodesMarkedAsLastNode: (nodeIds) => {
    return nodeIds.filter((id) => get().isMarkedAsGroupEndNode(id));
  },
  isNodeInsideGroupAndHasConnectionError: (nodeId) => {
    const groupId = get().getParentGroupIdForNode(nodeId);
    if (!groupId) return false;
    return Boolean(get().groupDetails[groupId]?.erroredNodes?.includes(nodeId));
  },

  getGroupErrorsCount: (groupId) => {
    const groupData = get().getNodeData(groupId);
    if (!isGroupNodeData(groupData)) return 0;
    const groupDetails = get().getGroupDetails(groupId);

    if (!groupData) return 0;
    return (
      groupData.nodeIds.reduce((acc, nodeId) => {
        return acc + get().validatorSlice.getNodeErrorsCount(nodeId);
      }, 0) + (groupDetails?.error ? 1 : 0)
    );
  },
});
