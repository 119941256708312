/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { service } from '@kiroboio/fct-sdk';

export const formatValue = ({
  value,
  decimals,
  decimalScale,
  ioType,
  isFocused,
}: {
  value: string;
  decimals?: number;
  decimalScale?: number;
  ioType?: 'INPUT' | 'OUTPUT';
  isFocused?: boolean;
}): string => {
  if (isFocused) {
    if (ioType === 'OUTPUT') return value;
    if (!decimalScale && value.slice(-1) === '.') {
      return value.slice(0, -1);
    }

    const [_, decimals] = value.split('.');
    if (!decimals) return value;
    if (decimalScale && decimals.length > decimalScale)
      return value.slice(0, -1);

    return value;
  }

  const getDigits = () => {
    // if(precise) return decimalScale
    if (!decimalScale) return 0;
    if (decimalScale <= 8 && ioType === 'OUTPUT') return 2;
    return decimalScale <= 8 ? decimalScale : 8;
  };

  const digits = getDigits();
  const isUsdFormat = digits === 2 && ioType === 'OUTPUT';

  const formatedValue = service.formatting.prebuild.formatValue({
    service: 'tokens',
    name: 'total',
    value,
    decimals: decimals || 0,
    digits: isUsdFormat ? undefined : digits,
    format: isUsdFormat ? '0,0.00' : undefined,
  });

  if (value && formatedValue === '-') return value;
  return formatedValue;
};

export const formatNumberValue = ({
  value,
  decimals,
  decimalScale,
}: {
  value: string | number;
  decimals?: number;
  decimalScale?: number;
}): string => {
  const getDigits = () => {
    if (!decimalScale) return 0;

    return decimalScale;
  };

  const digits = getDigits() || 0;

  const formatedValue = service.formatting.prebuild.formatValue({
    service: 'tokens',
    name: 'total',
    value,
    decimals: decimals || 0,
    digits: digits > 9 ? 9 : digits,
    format: undefined,
  });

  if (value && formatedValue === '-')
    return typeof value === 'string' ? value : String(value);
  return formatedValue;
};
