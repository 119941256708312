/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/prefer-immediate-return */
import type { ChainId, tokenTypes } from '@kiroboio/fct-core';
import { Utils } from '@kiroboio/fct-core';
import {
  useTokenList,
  useWallet,
  useVault,
  useNetwork,
} from '@kiroboio/fct-sdk';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { useFCTStore } from '../FCTStore';
import { useImportedTokensStore } from '~/lib/components/TokenPicker/importTokenStore';
import { getChainId } from '~/lib/utils/main';

export type TokenType = (typeof tokenTypes)[number];
export type FctTokenData = {
  address: string;
  token_address: string;
  iconUrl: string | undefined;
  logo?: string | undefined;
  mainAddress: string;
  label: string | undefined;
  rate: number;
  symbol: string;
  decimals: number;
  balance?: string;
  balanceUsd?: string;
  balanceUsdRaw?: string;
  type?: TokenType;
};

export interface FctToken extends FctTokenData {
  raw: FctTokenData;
  isImported?: boolean;
}

export interface FCTOptionToken {
  value: string;
  address: string;
  decimals: number;
  symbol: string;
  chainId: string | number;
  name?: string;
  logoURI?: string | undefined;
  type?: TokenType;
  isImported?: boolean;
}

export interface ChainlinkToken {
  category?: string;
  name: string;
  decimals: number;
  value: string;
  logoURI?: string;
}

export const isChainlinkToken = (option: any): option is ChainlinkToken => {
  return (
    typeof option === 'object' &&
    typeof option.name === 'string' &&
    typeof option.decimals === 'number' &&
    typeof option.value === 'string'
  );
};

export const isToken = (
  token: any
): token is FCTOptionToken | ChainlinkToken => {
  return Boolean(token.symbol);
};

export const isTokens = (
  tokens: any
): tokens is (FCTOptionToken | ChainlinkToken)[] => {
  return Array.isArray(tokens) && tokens.every(isToken);
};

const emptyFctOptionTokens: FCTOptionToken[] = [];
export const useCombineFctAndVaultTokens = (
  nodeId?: string,
  path?: string,
  options?: any[]
) => {
  const {
    data: {
      fmt: { chainId: chainIdSdk },
    },
  } = useNetwork();
  const { getParameterOptions } = useFCTStore((state) => state);
  const defaultFctOptionTokens = Utils.getSupportedTokens({
    chainId: chainIdSdk as ChainId,
  }) as FCTOptionToken[];
  const { list: walletTokens } = useTokenList({ account: 'wallet' });
  const { list: vaultTokens } = useTokenList({ account: 'vault' });
  const {
    data: {
      raw: { address: walletAddress },
    },
  } = useWallet();
  const {
    data: {
      raw: { address: vaultAddress },
    },
  } = useVault();
  const {
    data: { raw: chainIdStore },
  } = useNetwork();

  const chainId = getChainId(chainIdStore.netId);

  const getOptions = () => {
    if (options) return options;
    return nodeId && path
      ? ((getParameterOptions(nodeId, path) ||
          emptyFctOptionTokens) as FCTOptionToken[])
      : defaultFctOptionTokens;
  };
  const { fctOptionTokens, displayTokensType } = useFCTStore(
    (state) => ({
      displayTokensType:
        nodeId && path
          ? state.intentGraph?.getParam({
              id: nodeId,
              handle: path,
              handleType: 'INPUT',
            })?.value.jsonParam?.displayTokens || 'all'
          : 'all',
      fctOptionTokens: getOptions(),
    }),
    shallow
  );

  // console.log({ fctOptionTokens })
  const allTokens = useMemo(() => {
    if (chainId === '0') return [];
    const vaultTokensParsed = vaultTokens
      .slice(1, vaultTokens.length)
      .map((token): FCTOptionToken => {
        return {
          value: token.raw.token_address,
          address: token.raw.token_address,
          decimals: Number(token.raw.decimals),
          symbol: token.raw.symbol,
          chainId,
          name: token.raw.name,
          logoURI: token.raw.logo,
          type: 'DEFAULT',
        };
      });
    return vaultTokensParsed.concat(fctOptionTokens);
  }, [chainId, vaultTokens, fctOptionTokens]);

  const importedTokens = useImportedTokensStore(
    (state) => state.tokens[chainId] || []
  );

  const vaultTokenMap = useMemo(() => {
    return Object.fromEntries(
      vaultTokens.map((token) => [token.raw.token_address.toLowerCase(), token])
    );
  }, [vaultTokens]);

  const walletTokensMap = useMemo(() => {
    return Object.fromEntries(
      walletTokens.map((token) => [
        token.raw.token_address.toLowerCase(),
        token,
      ])
    );
  }, [walletTokens]);

  const selectedWalletAddress = useFCTStore((state) => {
    const value = nodeId ? state.getAccountInputValue(nodeId) : vaultAddress;
    return _.isString(value) ? value.toLocaleLowerCase() : '';
  });

  const accountTokens = useMemo(
    () => ({
      [vaultAddress.toLowerCase()]: vaultTokenMap,
      [walletAddress.toLowerCase()]: walletTokensMap,
    }),
    [vaultAddress, vaultTokenMap, walletAddress, walletTokensMap]
  );

  const getAccountToken = useCallback(
    (tokenAddress: string, accountAddress: string) => {
      const account = accountAddress.toLowerCase();
      const tokenMap = accountTokens[account] || {};
      return tokenMap[tokenAddress?.toLowerCase()];
    },
    [accountTokens]
  );

  const defaultOptions = useMemo(() => {
    const map: Record<string, FctToken> = {};

    (displayTokensType === 'options-only'
      ? fctOptionTokens || []
      : allTokens.concat(importedTokens) || []
    ).forEach((token) => {
      const address = token.value?.toLowerCase();
      const accountToken = getAccountToken(token.value, selectedWalletAddress);
      const logo =
        accountToken?.fmt?.logo ||
        (token.logoURI?.includes('question_mark') ? undefined : token.logoURI);
      const tokenType = token.type || 'DEFAULT';
      const createdTokenData = {
        address: token.value,
        token_address: token.value,
        iconUrl: logo,
        logo,
        mainAddress: token.value,
        label: token.name,
        rate: 0,
        symbol: token.symbol,
        decimals: token.decimals || 18,
        type: tokenType,
        balance: accountToken?.fmt?.balance || '0.00',
        balanceUsd: accountToken?.fmt?.balanceUsd || '0.00',
        balanceUsdRaw: accountToken?.raw?.balanceUsd || '0',
        isImported: token.isImported,
      };

      const createdToken = {
        ...createdTokenData,
        raw: createdTokenData,
      };

      map[`${address}_${tokenType}`] = createdToken;
    });

    const tokens = Object.values(map).sort((t1, t2) => {
      return parseFloat(t1?.balanceUsdRaw || '0') >
        parseFloat(t2?.balanceUsdRaw || '0') || t1.isImported
        ? -1
        : 1;
    });

    return tokens;
  }, [
    allTokens,
    displayTokensType,
    fctOptionTokens,
    getAccountToken,
    selectedWalletAddress,
    importedTokens,
  ]);

  return {
    tokens: defaultOptions,
  };
};
