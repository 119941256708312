import type {
  FitView,
  Project,
  Viewport,
  XYPosition,
} from '@kiroboio/reactflow';

import type { StoreSlice } from '.';

export type ReactFlowMirror = {
  project: Project;
  viewport: Viewport;
  fitView: FitView;
};

/*  
built to mirror the react flow state
to be used inside functions where needed without rerendering the whole app

  good usage:
    const myFunc = () => {
      const { project, viewport } = useFCTStore.getState()  used like refs
      do something with project and viewport
    }

  bad usage:
    const project = useFCTStore(state => state.project) will rerender every time the project changes
    const viewport = useFCTStore(state => state.viewport) will rerender every time the viewport changes

  good luck
*/
export const createReactFlowMirror: StoreSlice<ReactFlowMirror> = () => ({
  project: (pos: XYPosition) => pos,
  fitView: () => false,
  viewport: {
    zoom: 1,
    x: 0,
    y: 0,
  },
});
