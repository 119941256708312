/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import type { InputProps } from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { useState, useEffect, forwardRef } from 'react';
import { FCT_INPUT_STYLES_INTENT } from './builder/Inputs';
import { type NumberFormatValues, type SourceInfo } from 'react-number-format';

import { formatValue } from './NumberInput/utils';

const MIN_WIDTH = 1;
const MAX_WIDTH = 50;
const TRANSITION = 'all 0.3s ease-in-out';

const calcNumericExtra = (value: unknown) => {
  if (!value) return MIN_WIDTH;
  if (typeof value !== 'string') return MIN_WIDTH;

  return value?.length;
};

const calcEffectiveLength = (
  value: any,
  initWidth: number = MIN_WIDTH,
  extraWidth?: number
) => {
  const contentEstimatedLength = calcNumericExtra(value) + (extraWidth || 1);
  return Math.max(Math.min(MAX_WIDTH, contentEstimatedLength), initWidth);
};

export const DynamicWidthInput = forwardRef(
  (
    {
      isDynamicWidth,
      initWidth,
      extraWidth,
      value,
      style,
      isNumericString,
      ...rest
    }: {
      isDynamicWidth?: boolean;
      initWidth?: number;
      extraWidth?: number;
      readOnly?: boolean;
      thousandSeparator?: string | boolean;
      isNumericString?: boolean;
      decimalScale?: number;
      onValueChange?: (
        values: NumberFormatValues,
        sourceInfo: SourceInfo
      ) => void;
    } & InputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const getValue = () => {
      if (!isFocused && isNumericString && typeof value === 'string') {
        return formatValue({
          value,
          decimalScale: rest.decimalScale || 0,
          ioType: 'INPUT',
          isFocused,
        });
      }

      return value;
    };

    const [length, setLength] = useState(
      calcEffectiveLength(getValue(), initWidth, extraWidth)
    );

    useEffect(() => {
      const value = getValue();
      setLength(calcEffectiveLength(value, initWidth, extraWidth));
    }, [value, isFocused]);

    if (!isDynamicWidth) {
      return <Input ref={ref} value={value || ''} {...rest} />;
    }

    const {
      textDecorationLine,
      textDecorationColor,
      textDecorationStyle,
      textUnderlineOffset,
      ...FCT_INPUT_STYLES
    } = rest;

    return (
      <Input
        spellCheck={false}
        _hover={{
          minWidth: `${length}ch`,
          backgroundColor: '#707070',
          transition: TRANSITION,
          zIndex: 99,
        }}
        _focusWithin={{
          minWidth: `${length + 2}ch`,
          transition: TRANSITION,
          backgroundColor: '#0068a8',
          outline: '2px solid',
          // textDecorationLine: 'none',
          // outline: '2px solid #0068a8',
          zIndex: 100,
        }}
        style={{
          width: `${length}ch`,
          height: `2ch`,
          margin: '6px 2px',
          padding: '0px',
          // backgroundColor: '#404040',
          ...(style || {}),
          fontFamily: 'monospace',
          transition: TRANSITION,
        }}
        display="flex"
        _focusVisible={{}}
        ref={ref}
        flexWrap="wrap"
        onFocus={(e) => {
          setIsFocused(true);
          rest.onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          rest.onBlur?.(e);
        }}
        {...FCT_INPUT_STYLES}
        {...FCT_INPUT_STYLES_INTENT}
        value={value || ''}
        textDecorationLine={
          textDecorationLine || FCT_INPUT_STYLES_INTENT.textDecorationLine
        }
        textDecorationColor={
          textDecorationColor || FCT_INPUT_STYLES_INTENT.textDecorationColor
        }
        textDecorationStyle={textDecorationStyle}
        textUnderlineOffset={textUnderlineOffset}
      />
    );
  }
);

// @ts-expect-error: chakra problem https://github.com/chakra-ui/chakra-ui/issues/2269
DynamicWidthInput.id = 'Input';
