/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/prefer-single-boolean-return */
import type { IPluginParams } from '@kiroboio/fct-core';
import { FctParam, isVariable } from '@kiroboio/fct-core';
import _ from 'lodash';
import { useMemo } from 'react';
import { isAddress as v_isAddress } from 'web3-validator';
import type { BaseSchema, ObjectSchema } from 'yup';
import { object as yupObject, string as yupString } from 'yup';
import type { ObjectShape } from 'yup/lib/object';

type Value = string | null | undefined;
const isNumeric = (value: Value) => {
  const num = Number(value);
  return !Number.isNaN(num) && Number.isInteger(num);
};

const isArray = (value: Value) => {
  if (!value) return false;
  try {
    return Array.isArray(JSON.parse(value));
  } catch (error) {
    return false;
  }
};

const isAddress = (value: Value) => {
  if (!value?.startsWith('0x')) return false;
  try {
    return v_isAddress(value);
  } catch {
    return false;
  }
};

const checkArrayType = (
  predicate: (value: string) => boolean,
  value: Value
) => {
  try {
    const arr = JSON.parse(value as string) as string[];
    if (arr.some((v) => typeof v !== 'string')) return false;
    return arr.every(predicate);
  } catch (error) {
    return false;
  }
};

const booleanValidator = yupString()
  .required('boolean required')
  .test('is boolean', 'must be a boolean (true or false)', (value) => {
    if (!value) return false;
    return /^(true|false)$/.test(value);
  });

const addressValidator = yupString()
  .required('address required')
  .test('length', 'Should be 42 characters', (value) => value?.length === 42)
  .test('address-wrong', 'Invalid address', isAddress);

const integerValidator = yupString().test(
  'is numeric',
  'must be a number',
  (value) => {
    if (!value) return true;
    if (value === '') return false;
    try {
      if (isVariable(JSON.parse(value))) return true;
      return isNumeric(value);
    } catch (e) {
      return isNumeric(value);
    }
  }
);

// please check that the value is between 0 and 18
const decimalsValidator = yupString()
  .test('is numeric', 'must be a number', (value) => {
    if (value === '') return false;
    return isNumeric(value);
  })
  .test('is between 0 and 18', 'must be between 0 and 18', (value) => {
    if (value === '') return false;
    return Number(value) >= 0 && Number(value) <= 18;
  });

const integerArrayValidator = yupString()
  .nullable()
  .test('is array', `Doesn't look like a valid array`, isArray)
  .test(
    'is array of strings',
    'Must be an array of string numbers ["10000","20000"]',
    checkArrayType.bind(null, isNumeric)
  );

const addressArrayValidator = yupString()
  .nullable()
  .test('is array', `Doesn't look like a valid array`, isArray)
  .test(
    'is array of strings',
    'Must be an array of addresses ["0x...","0x..."]',
    checkArrayType.bind(null, isAddress)
  );

const stringArrayValidator = yupString()
  .nullable()
  .test('is array', `Doesn't look like a valid array`, isArray)
  .test(
    'is array of strings',
    'Must be an array of addresses ["...","..."]',
    checkArrayType.bind(null, (v) => typeof v === 'string')
  );

const variableValidator = yupString()
  .nullable()
  .test('is variable', `Doesn't look like a valid variable`, (value) => {
    if (!value) return false;
    try {
      if (isVariable(JSON.parse(value))) return true;
      return false;
    } catch (e) {
      return false;
    }
  });

const stringValidator = yupString();

export const yupValidators = {
  boolean: booleanValidator,
  address: addressValidator,
  integer: integerValidator,
  decimals: decimalsValidator,
  string: stringValidator,
  variable: variableValidator,
  'integer[]': integerArrayValidator,
  'address[]': addressArrayValidator,
  'string[]': stringArrayValidator,
};

export type FCTParamValidatorTypes = keyof typeof yupValidators;

export type ParamsLike = { [key: string]: InstanceType<typeof FctParam> };

export const yuppifyParams = (
  params: IPluginParams['input'] | Record<string, IPluginParams['input']>
): ObjectSchema<ObjectShape> => {
  return yupObject().shape(
    _.mapValues(params, (value) => {
      if (value instanceof FctParam) {
        if (value.isVariable) return yupValidators.variable;
        return yupValidators[value.appType as keyof typeof yupValidators];
      }
      return yuppifyParams(value as IPluginParams['input']);
    }) as Record<string, BaseSchema>
  );
};

export const useYupValidators = <P extends IPluginParams['input']>(
  params: P
) => {
  const scheme = useMemo(() => {
    return yuppifyParams(params);
  }, [params]);

  return { scheme };
};
