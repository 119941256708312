import type { ConnectionLineType } from '@kiroboio/reactflow';

import type { StoreSlice } from '.';

export type DiagramSettingsSlice = {
  diagramSettings: {
    edgeType: `${ConnectionLineType}`;
    setEdgeType: (edgeType: `${ConnectionLineType}`) => void;
  };
};

export const createDiagramSettingsSlice: StoreSlice<DiagramSettingsSlice> = (
  set
) => ({
  diagramSettings: {
    edgeType: 'default',
    setEdgeType: (edgeType) => {
      set((state) => ({
        diagramSettings: { ...state.diagramSettings, edgeType },
      }));
    },
  },
});
