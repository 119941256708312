import type { BadgeProps } from '@chakra-ui/react';
import { Badge } from '@chakra-ui/react';
import type { FC } from 'react';
import { useNetwork } from 'wagmi';

export const NetworkNameBadge: FC<BadgeProps> = (props) => {
  const { chain } = useNetwork();
  const bg: BadgeProps['colorScheme'] = chain?.testnet ? 'orange' : 'green';

  return (
    <Badge variant="subtle" colorScheme={bg} px="2" {...props} rounded="full">
      {chain?.name}
    </Badge>
  );
};
