import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import type { FCTOptionToken } from '../builder/Provider/hooks/useCombineFctAndVaultTokens';
import { isEqualAddresses } from '~/lib/utils/address';

interface Store {
  tokens: {
    [chainId: string]: FCTOptionToken[];
  };
  addToken: (chainId: string, tokenDetails: FCTOptionToken) => void;
}

export const useImportedTokensStore = create(
  persist<Store>(
    (set) => ({
      tokens: {},
      addToken: (chainId: string, tokenDetails: FCTOptionToken) => {
        set((state) => {
          if (
            state.tokens[chainId] &&
            state.tokens[chainId].find((token) =>
              isEqualAddresses(token.value, tokenDetails.value)
            )
          )
            return state;
          return {
            tokens: {
              ...state.tokens,
              [chainId]: [...(state.tokens[chainId] || []), tokenDetails],
            },
          };
        });
      },
    }),
    {
      name: 'imported-tokens',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
