export const FCT_INPUT_STYLES = {
  bg: 'unset',
  className: 'nodrag',
  colorScheme: 'gray',
  border: '1px',
  borderColor: 'gray.700',
  flex: '1',
  maxW: 'full',
  minW: '32px',
  rounded: 'lg',
  size: 'xs',
  _placeholder: {
    color: 'gray.500',
  },
};

export const FCT_INPUT_STYLES_INTENT = {
  bg: 'none',
  border: 0,
  minW: '12px',
  rounded: 'md',
  size: 'xs',
  padding: '4px',
  fontWeight: 600,
  textDecorationLine: 'underline',
  textDecorationStyle: 'solid',
  textDecorationColor: 'white',
  textUnderlineOffset: '20px',
} as const;

export const FCT_SCROLL_STYLES = {
  // height: '426px',
  // overflow: 'auto',
  width: '100%',
  alignItems: 'center',
  // paddingLeft: '16px',
  // paddingRight: '16px',
  paddingBottom: '52px',
  // boxShadow: 'inset 0 0 6px black',
  // borderRadius: '4px',
  // backgroundColor: '#2D3339',
};

export const FCT_SCROLL_ITEM_STYLES = {
  // borderColor: '#333943',
  // boxShadow: 'inset 0 0 6px #101010',
  // background: '#00000020',
};
