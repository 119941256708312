import { type StoreSlice } from '.';

export type FlowSlice = {
  flowSlice: {
    flows: Record<string, { success: string[]; fail: string[] }>;
    getNextNodeId: (
      nodeId: string,
      flow: 'success' | 'fail'
    ) => string | undefined;
    isInNodeSuccessPath: (nodeId: string, targetId: string) => boolean;
    isInNodeFailPath: (nodeId: string, targetId: string) => boolean;
    isAdvanced: boolean;
    getIsAdvanced: () => boolean;
    setIsAdvanced: (isEnabled: boolean) => void;
  };
};

const IS_ADVANCED_STORAGE_LABEL = 'fct:settings:isadanced';
export const createFlowSlice: StoreSlice<FlowSlice> = (set, get) => ({
  flowSlice: {
    flows: {},
    isAdvanced: localStorage.getItem(IS_ADVANCED_STORAGE_LABEL) === 'true',
    setIsAdvanced: (isAdvanced: boolean) => {
      localStorage.setItem(IS_ADVANCED_STORAGE_LABEL, `${isAdvanced}`);
      set((state) => {
        return {
          flowSlice: { ...state.flowSlice, isAdvanced },
        };
      });
    },

    getIsAdvanced: () => {
      return get().flowSlice.isAdvanced;
    },
    getNextNodeId: (nodeId, flow) => {
      const flowNodeIds = get().flowSlice.flows[nodeId]?.[flow];
      return flowNodeIds?.[0];
    },

    isInNodeSuccessPath: (nodeId, targetId) => {
      const successNodeIds = get().flowSlice.flows[nodeId]?.success || [];
      if (successNodeIds.length > 1) return successNodeIds?.includes(targetId);
      return successNodeIds?.[0] === targetId;
    },

    isInNodeFailPath: (nodeId, targetId) => {
      const failNodeIds = get().flowSlice.flows[nodeId]?.fail || [];
      if (failNodeIds.length > 1) return failNodeIds?.includes(targetId);
      return failNodeIds?.[0] === targetId;
    },
  },
});
