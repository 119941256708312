/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-useless-escape */
import type { Connection, EdgeChange, NodeChange } from '@kiroboio/reactflow';
import { merge, pick } from 'lodash';

import type {
  GroupNodeData,
  NodeState,
  PluginNodeData,
} from '../../Nodes/nodes';
import {
  createGroupIOMarkerId,
  isGroupMarkerId,
} from '../../Nodes/utils/createNode';
import type { FCTProject } from '../FCTProjectProvider';
import { getValidNodeType } from '../hooks/getValidNodeType';
import { createStartNode, isInputOrOutputHandle } from '../utils';

import type {
  Edge,
  FCTNode,
  IntentGraph,
  NodeData,
  UIParamJSONData,
} from '@kiroboio/fct-builder';
import { create, getEdgeName } from '@kiroboio/fct-builder';
import type { ChainId } from '@kiroboio/fct-core';
import graphlib from 'graphlib';
import type { StoreSlice } from '.';
import { updateNode, updateNodes } from '.';

export type EdgeId = {
  source?: string | null;
  sourceHandle?: string | null;
  target?: string | null;
  targetHandle?: string | null;
};
export type FctConnection = Connection & {
  type?: string;
  isTargetMultiInput?: boolean;
  hidden?: boolean;
};

export type IntentGraphEdge = Edge;
export type FctEdge = Edge & {
  type?: string;
  isTargetMultiInput?: boolean;
  hidden?: boolean;
  isInput?: boolean;
  multicallGroupIndex?: number;
  groupOrderIndex?: number;
};
/**
 * Represents the main slice of the FCTStore.
 */
export type MainSlice = {
  g: graphlib.Graph;
  intentGraph?: IntentGraph;
  shouldCalculateValues: boolean;
  setShouldCalculateValues: (shouldCalculateValues: boolean) => void;
  generateGraph: () => void;
  _nodesIndexMap: Record<string, number>;
  nodes: FCTNode[];
  displayNodes: Map<string, FCTNode>;
  updateDisplayNodes: () => void;
  edges: FctEdge[];
  setProject: (project: FCTProject) => void;
  displayEdges: Map<string, FctEdge>;
  createDisplayEdges: () => void;
  // changeDisplayEdge: (edge: FctEdge) => Map<string, FctEdge>;
  updateEdges: (groupId?: string) => void;
  getDisplayNodes: (p: { nodes: FCTNode[] }) =>
    | {
        map: Map<string, FCTNode>;
        list: FCTNode[];
      }
    | undefined;
  getDisplayEdges: () => FctEdge[];
  // getDisplayEdge: (edge: FctEdge) => FctEdge;
  addNode: (node: FCTNode) => void;
  addNodes: (node: FCTNode[]) => void;
  getNodes: (ids: string[]) => FCTNode[];
  editNode: (node: Partial<FCTNode>, id: string) => void;
  editNodes: (nodes: Partial<FCTNode>[]) => void;
  editNodeInputs: (
    nodeData: Partial<PluginNodeData>,
    id: string,
    calculatedParamsKeys?: string[]
  ) => void;
  clearNodes: () => void;
  setNodes: () => void;
  setNodes2: (nodes: NodeChange[]) => void;
  setEdgeChanges: (edgeChange: EdgeChange[]) => void;
  setEdges: (edges: Edge[]) => void;
  addEdge: (edge: FctConnection) => void;
  addEdges: (
    edges: (FctConnection | FctEdge)[],
    isConnectGroupsEdges?: boolean
  ) => void;
  removeAndAddEdges: (
    edgesToRemove: (FctConnection & { id: string })[],
    edge: FctConnection & { id?: string }
  ) => void;
  /**
   * Sets the data of a node with the specified ID.
   * @param id - The ID of the node to set the data for.
   * @param data - The partial data to set for the node.
   * @param replace - If true, replaces the existing data with the new data. If false or undefined, merges the new data with the existing data.
   */
  setNodeData: (id: string, data: Partial<NodeData>, replace?: boolean) => void;
  getNode: <TData extends NodeData = NodeData>(
    id: string
  ) => FCTNode<TData> | undefined;
  getNodeIndex: (id: string) => number;
  getNodeData: <TData extends NodeData = NodeData>(
    id: string
  ) => TData | undefined;
  getNodeInputParams: (id: string) => UIParamJSONData[];
  getNodeState: (id: string) => NodeState;
  removeNode: (id: string, isUngroup?: boolean) => void;
  removeNodes: (id: string[]) => void;
  removeEdge: (edge: IntentGraphEdge & { id?: string }) => void;
  getEdge: (id: string) => FctEdge | undefined;
  setElements: (elements: FCTProject) => void;
};
export const getEdgeId = ({
  source,
  sourceHandle,
  target,
  targetHandle,
}: {
  source?: string | null;
  sourceHandle?: string | null;
  target?: string | null;
  targetHandle?: string | null;
}) =>
  `@kiroboio/reactflow__edge-${source}${sourceHandle || ''}-${target}${
    targetHandle || ''
  }`;

const getEdgeNameFromId = (edgeId: string) => {
  if (edgeId.startsWith(`@sumbat/reactflow__edge-`))
    return edgeId.replace(`@sumbat/reactflow__edge-`, '');
  if (edgeId.startsWith(`@kiroboio/reactflow__edge-`))
    return edgeId.replace(`@kiroboio/reactflow__edge-`, '');

  return edgeId;
};

export const createMainSlice: StoreSlice<MainSlice> = (set, get) => ({
  g: new graphlib.Graph(),
  intentGraph: undefined,
  shouldCalculateValues: true,
  setShouldCalculateValues: (shouldCalculateValues) => {
    set({ shouldCalculateValues });
  },
  generateGraph: () => {
    set({
      g: get().intentGraph?.g,
    });
  },
  _nodesIndexMap: {},
  nodes: [],
  displayNodes: new Map(),
  displayEdges: new Map(),
  edges: [],
  updateDisplayNodes: () => {
    const displayNodes = get().getDisplayNodes({ nodes: get().nodes || [] });
    if (!displayNodes) return;
    const { list, map } = displayNodes;
    set({
      displayNodes: map,
      nodes: list,
    });
  },
  createDisplayEdges: () => {
    // const edgesToDisplay: Map<string, Edge> = new Map();
    const edges = get().getDisplayEdges();
    set({
      edges,
    });
  },
  updateEdges: (groupId) => {
    const { intentGraph } = get();
    if (groupId) {
      intentGraph?.setGroupInputs(groupId);
    }

    set(() => ({
      edges: intentGraph?.edges as FctEdge[],
    }));
  },
  setProject: (project) => {
    get().intentGraph = create({
      useDefaultConfig: true,
      chainId: String(project.chainId) as ChainId,
      provider: project.provider,
      service: project.service,
      calculateValuesOnUserInput: project.calculateValuesOnUserInput,
      getOutputValuesOnUserInput: project.getOutputValuesOnUserInput,
      wallet: project.wallet,
      account: project.vault,
      updateAmountOnDecimalsChange: false,
    });
    if (!project.nodes?.length) {
      const startNode = createStartNode();
      get().intentGraph?.setNode({ node: startNode });
    }
    const { intentGraph } = get();
    // project.nodes.forEach((node) => intetnGraph?.setNode({ node }));
    // project.edges.forEach((edge) => intetnGraph?.setEdge(edge as any));

    intentGraph?.setDraft(project);
    const intentNodes = intentGraph?.nodes;
    const intentEdges = intentGraph?.edges as FctEdge[];

    set({
      nodes: intentNodes || [],
      edges: intentEdges || [],
    });

    get().updateDisplayNodes();
  },
  getDisplayNodes: ({ nodes }) => {
    // get().displayNodes.clear()
    const state = get();
    const { intentGraph } = state;
    const selectedGroupData = state.getNodeData<GroupNodeData>(
      state.selectedGroup
    );
    if (!intentGraph) return;
    if (selectedGroupData && selectedGroupData.nodeIds) {
      const groupNodeIds = selectedGroupData.nodeIds;
      const inputMarkerId = createGroupIOMarkerId(state.selectedGroup, 'input');
      const outputMarkerId = createGroupIOMarkerId(
        state.selectedGroup,
        'output'
      );

      nodes.forEach((n) => {
        const node = merge(
          { type: getValidNodeType(n.type), id: n.id },
          {
            invisible:
              !groupNodeIds.includes(n.id) &&
              n.id !== inputMarkerId &&
              n.id !== outputMarkerId,
          }
        );
        intentGraph?.editNodeContainer({ node: node as NodeChange });
      });

      return { map: intentGraph?.nodesSignal.value, list: intentGraph?.nodes };
    }

    const map = nodes?.reduce(
      (acc, node) => {
        if (node.type === 'function-group') {
          (node.data as GroupNodeData).nodeIds?.forEach((id) => {
            acc[id] = node.id;
          });
        }
        return acc;
      },
      {} as Record<string, string>
    );

    nodes?.forEach((n) => {
      const node = merge(
        { type: getValidNodeType(n.type), id: n.id },
        {
          invisible: Boolean(map[n.id]) || isGroupMarkerId(n.id),
        }
      );

      intentGraph?.editNodeContainer({ node: node as NodeChange });
    });

    return { map: intentGraph?.nodesSignal.value, list: intentGraph?.nodes };
  },
  getDisplayEdges: () => {
    const { intentGraph } = get();
    if (!intentGraph) return [];
    // const edges = intentGraph.toggleGroupMarkers(get().selectedGroup, false)

    // return edges

    const prevEdges = intentGraph.edges;
    prevEdges.forEach((e) => {
      const isReflowConnection = intentGraph?.isReflowConnection(
        e.sourceHandle,
        e.targetHandle
      );
      if (isReflowConnection) return;
      const isInputOrOutput =
        isInputOrOutputHandle(e.sourceHandle) ||
        isInputOrOutputHandle(e.targetHandle);

      const isGroupInputMarker = isGroupMarkerId(e.source);
      const isGroupOutputMarker = isGroupMarkerId(e.target);

      const edgeName = getEdgeName(e);
      intentGraph.editEdge(edgeName, {
        hidden:
          !get().selectedGroup &&
          (isGroupMarkerId(e.source) || isGroupMarkerId(e.target)),
        animated: !isInputOrOutput,
        style: {
          stroke: isGroupInputMarker
            ? 'var(--chakra-colors-purple-500)'
            : isGroupOutputMarker
              ? 'var(--chakra-colors-green-500)'
              : isInputOrOutput
                ? 'var(--chakra-colors-orange-500)'
                : 'var(--chakra-colors-white)',
        },
      });
    });

    return intentGraph.edges;
  },

  addNode: (node) => {
    const state = get();
    const { intentGraph } = state;

    intentGraph?.setNode({ node, selectedGroup: state.selectedGroup });
    set(() => ({ nodes: intentGraph?.nodes }));
  },

  addNodes: (nodes) => {
    const { intentGraph } = get();
    if (!intentGraph) return;

    nodes.forEach((node) => intentGraph?.setNode({ node }));
    set((state) => ({ nodes: intentGraph.nodes }));
  },

  editNode: (node, id) => {
    const { intentGraph } = get();

    intentGraph?.editNode({ node: { ...node, id } });
    set(() => ({ nodes: intentGraph?.nodes }));
  },

  editNodes: (nodes) => {
    const updatedNodesIds: string[] = [];
    const updatedNodes = get().nodes.map((prevNode) => {
      const newNode = nodes.find((n) => n.id === prevNode.id);
      if (!newNode) return prevNode;

      updatedNodesIds.push(newNode.id as string);
      return { ...prevNode, ...newNode };
    });

    set(() => ({ nodes: updatedNodes }));
    if (updatedNodesIds.length) updateNodes(updatedNodesIds);
  },

  getNodes: (ids: string[]) => {
    const nodes: FCTNode[] = [];
    ids.forEach((id) => {
      const node = get().getNode(id);
      if (node) nodes.push(node);
    });

    return nodes;
  },
  editNodeInputs: (nodeData, id, calculatedParamsKeys) => {
    set((state) => {
      const displayNode = state.displayNodes.get(id);
      const getNewNode = () => {
        const prevNode = displayNode;
        if (!prevNode) return;
        if (prevNode.id !== id) return prevNode;
        if (!prevNode.data) return prevNode;

        const prevNodeData = prevNode.data;
        if (!('input' in prevNodeData)) return prevNode;
        if (!('inputList' in prevNodeData)) return prevNode;
        const inputList =
          nodeData.inputList?.map((newItem) => {
            if (calculatedParamsKeys?.includes(newItem.key)) {
              return newItem;
            }
            const prevValue = prevNodeData.inputList?.find(
              (prevItem) => prevItem.key === newItem.key
            );
            if (!prevValue) return newItem;
            const { value, ...rest } = newItem;
            return Object.assign(prevValue, rest);
          }) || [];

        const outputList = nodeData.outputList || [];

        return {
          ...prevNode,
          data: {
            ...prevNode.data,
            input: { ...prevNodeData.input, ...nodeData.input },
            inputList,
            inputWithMeta: { ...prevNodeData.inputWithMeta },
            outputList,
          },
        };
      };

      const newNode = getNewNode();
      if (newNode) state.displayNodes.set(id, newNode);

      return {
        displayNodes: state.displayNodes,
        nodes: Array.from(state.displayNodes.values()),
      };
    });

    updateNode(id);
  },
  clearNodes: () => {
    set(() => ({ nodes: [], displayNodes: new Map() }));
  },
  setNodes: () => {
    const { intentGraph } = get();

    set(() => ({
      nodes: intentGraph?.nodes,
      edges: intentGraph?.edges,
    }));
  },

  setNodes2: (changes: NodeChange[]) => {
    const { intentGraph } = get();
    changes.forEach((change) => {
      const { type, ...rest } = change;
      intentGraph?.editNodeContainer({ node: rest as NodeChange });
    });
    set(() => ({ nodes: intentGraph?.nodes || [] })); // displayNodes: intentGraph?.nodesSignal.value  }));
  },

  setEdgeChanges: (changes: EdgeChange[]) => {
    const { intentGraph } = get();
    if (!intentGraph) return;

    changes.forEach((change) => {
      if (!(`id` in change) || !change.id) return;

      const groupTarget = change.id.match(/\g\|.*$/)?.[0];
      let edgeName = getEdgeNameFromId(change.id);
      if (groupTarget) {
        const groupId = groupTarget.replaceAll(/start|success|fail|in/g, '');
        const groupEdgeName = change.id.replace(
          groupId,
          intentGraph.getGroupFirstNodeIdFromGroupId({ target: groupId })
        );

        edgeName = getEdgeNameFromId(groupEdgeName);
      }
      const { type, ...rest } = change;
      intentGraph.editEdge(edgeName, rest);
    });

    set(() => ({ edges: intentGraph?.edges || [] }));
  },

  setEdges: (edges) => {
    set((state) => ({
      ...state,
      edges,
    }));
  },

  addEdge: (edge) => {
    const isGroupOutput = isGroupMarkerId(edge.target);
    if (
      isGroupOutput &&
      (edge.sourceHandle === 'success' || edge.sourceHandle === 'fail')
    )
      return;

    const { intentGraph } = get();
    intentGraph?.setEdge(edge as Edge);
    const edges = intentGraph?.edges as FctEdge[];

    set((state) => ({
      edges,
    }));
  },
  addEdges: (
    edges: ((FctConnection | FctEdge) & { id?: string })[],
    isConnectGroupsEdges
  ) => {
    const { intentGraph } = get();
    if (!intentGraph) return;

    const newEdges = edges.map((edge) => {
      if ('id' in edge && edge.id) return edge;
      edge.id = getEdgeId(edge);

      return edge;
    });

    const edgesToAdd = [
      ...Array.from(get().displayEdges.values()),
      ...newEdges,
    ] as Edge[];

    edgesToAdd.forEach((edge) =>
      intentGraph.setEdge(edge, isConnectGroupsEdges)
    );
    set(() => ({
      edges: intentGraph.edges,
    }));

    // get().createDisplayEdges()
  },
  removeAndAddEdges: (edgesToRemove, edge) => {
    const { intentGraph } = get();
    if (!intentGraph) return;
    edgesToRemove.forEach((_edge) => {
      intentGraph.removeEdge(_edge as Edge);
    });

    if (!edge.id) {
      edge.id = getEdgeId(edge);
    }
    get().addEdge(edge);

    set(() => ({
      edges: intentGraph.edges,
    }));
  },
  setNodeData: (id, data, replace) => {
    const node = get().getNode(id);
    console.log({ data, node });
    if (!node) return;
    const { intentGraph } = get();

    intentGraph?.editNodeData({ id, data });
    set(() => {
      return {
        nodes: intentGraph?.nodes || [],
      };
    });
  },

  getNode: <TData extends NodeData = NodeData>(id: string) => {
    return get().intentGraph?.getNode({ id }) as FCTNode<TData> | undefined;
  },
  getNodeIndex: (id: string) => {
    return get()._nodesIndexMap[id];
  },
  getNodeData: <TData extends NodeData = NodeData>(id: string) => {
    return get().getNode(id)?.data as TData | undefined;
  },
  getNodeInputParams: (id: string) => {
    const { intentGraph } = get();
    if (!intentGraph) return [];

    const node = get().getNode(id);
    const pluginInstance = node?.pluginInstance;
    if (!pluginInstance) return [];

    return pluginInstance.input.paramsList.map(({ param, key }) => {
      const { isMultiParam } = param;
      return intentGraph.getParamMeta({
        id,
        handle: key,
        handleType: 'INPUT',
        isMultiParam,
      }) as UIParamJSONData;
    });
  },
  getNodeState: (id) =>
    pick(get().getNode(id), [
      'selectable',
      'dragging',
      'selected',
      'resizing',
      'connectable',
      'deletable',
      'draggable',
      'focusable',
    ]),
  removeNode: (id, isUngroup) => {
    const {
      validatorSlice: { errors },
      intentGraph,
      selectedGroup,
    } = get();

    intentGraph?.removeNode({ id, selectedGroup, isUngroup });
    set({
      nodes: intentGraph?.nodes || [],
      edges: intentGraph?.edges || [],
    });
    delete get().plugins[id];
    // get().pointersSlice.clearValues(id);
    if (errors && errors[id]) {
      delete errors[id];
    }
  },
  removeNodes: (ids) => {
    const {
      displayNodes,
      validatorSlice: { errors },
      intentGraph,
    } = get();

    ids.forEach((id) => {
      intentGraph?.removeNode({ id });
      displayNodes.delete(id);
    });
    // const filteredNodes = Array.from(displayNodes.values());
    // if (nodes.length === filteredNodes.length) return;

    // ids.forEach((id) => get().displayNodes.delete(id));
    ids.forEach((id) => {
      delete get().plugins[id];
      get().pointersSlice.clearValues(id);
      if (errors && errors[id]) {
        delete errors[id];
      }
    });

    set({
      nodes: intentGraph?.nodes || [],
      edges: intentGraph?.edges || [],
    });
  },
  removeEdge: (edge: IntentGraphEdge & { id?: string }) => {
    const { intentGraph } = get();
    // if (!edge) return;
    intentGraph?.removeEdge(edge);
    set(() => ({
      edges: intentGraph?.edges as FctEdge[],
    }));
  },
  getEdge: (id) => {
    return get().displayEdges.get(id);
  },

  setElements: (project) => {
    const { nodes, edges } = project;
    get().intentGraph = create({
      useDefaultConfig: true,
      provider: project.provider,
      service: project.service,
      calculateValuesOnUserInput: project.calculateValuesOnUserInput,
      getOutputValuesOnUserInput: project.getOutputValuesOnUserInput,
      chainId: String(project.chainId) as ChainId,
      wallet: project.wallet,
      account: project.vault,
      updateAmountOnDecimalsChange: false,
    });

    const { intentGraph } = get();
    intentGraph?.setDraft({ nodes, edges });
    set({
      nodes: intentGraph?.nodes || [],
      edges: intentGraph?.edges || [],
    });

    get().updateDisplayNodes();
  },
});
