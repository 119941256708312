import _ from 'lodash';
import type { StoreSlice } from '.';
import { isPluginNode } from '../../Nodes/nodes';

type NodeId = string;

export type TestRunState = {
  isCalculating: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  isSimulationNeeded?: boolean;
  calculatingFieldNames?: string[];
  successTime?: Date;
  failTime?: Date;
};

export type TestRunSlice = {
  testRunSlice: {
    nodes: Record<NodeId, TestRunState>;
    getTestRunState: (nodeId: NodeId) => TestRunState | undefined;
    getGroupTestRunState: (nodeIds: NodeId[]) => TestRunState | undefined;
    getTestRunStateAllNodes: (
      nodeIds: NodeId[]
    ) => (TestRunState & { nodeId: string })[] | undefined;
    setCalculating: (nodeId: NodeId, calculatingFieldNames?: string[]) => void;
    setFetching: (nodeId: NodeId) => void;
    setLoading: (nodeId: NodeId) => void;
    resetSimulateResult: (nodeId: NodeId) => void;
    setSuccess: (nodeId: NodeId) => void;
    setSimulationError: (nodeId: NodeId, error: string) => void;
    setError: (nodeId: NodeId, error: string) => void;
    reset: (nodeId: NodeId) => void;
    resetFetching: (nodeId: NodeId) => void;
    resetCalculating: (nodeId: NodeId, isSimulationNeeded?: boolean) => void;
    resetLoading: (nodeId: NodeId) => void;
    resetAll: () => void;
  };
};

export const createTestRunSlice: StoreSlice<TestRunSlice> = (set, get) => ({
  testRunSlice: {
    nodes: {},
    setCalculating: (nodeId, calculatingFieldNames) => {
      set((state) => {
        const stateLoading = state.testRunSlice.getTestRunState(nodeId) || {};
        return {
          testRunSlice: _.merge({}, state.testRunSlice, {
            nodes: {
              [nodeId]: {
                ...stateLoading,
                isCalculating: true,
                isSimulationNeeded: false,
                calculatingFieldNames,
              },
            },
          }),
        };
      });
    },
    setFetching: (nodeId) => {
      set((state) => {
        const stateLoading = state.testRunSlice.getTestRunState(nodeId) || {};
        return {
          testRunSlice: _.merge({}, state.testRunSlice, {
            nodes: {
              [nodeId]: {
                ...stateLoading,
                isFetching: true,
              },
            },
          }),
        };
      });
    },
    setLoading: (nodeId) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              isCalculating: false,
              isFetching: false,
              isLoading: true,
              isSuccess: false,
              isError: false,
              error: '',
              successTime: undefined,
              failTime: undefined,
            },
          },
        }),
      }));
    },
    setSuccess: (nodeId) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              isCalculating: false,
              isFetching: false,
              isSuccess: true,
              isLoading: false,
              isError: false,
              error: '',
              successTime: new Date(),
              failTime: undefined,
            },
          },
        }),
      }));
    },

    setSimulationError: (nodeId, error) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              isCalculating: false,
              isFetching: false,
              isLoading: false,
              isSuccess: false,
              isError: true,
              error,
              successTime: undefined,
              failTime: new Date(),
            },
          },
        }),
      }));
    },
    resetSimulateResult: (nodeId) => {
      set((state) => {
        const nodeState = (state.testRunSlice.getTestRunState(nodeId) ||
          {}) as TestRunState;
        return {
          testRunSlice: {
            ...state.testRunSlice,
            nodes: {
              ...state.testRunSlice.nodes,
              [nodeId]: {
                ...(nodeState || {}),
                successTime: undefined,
                failTime: undefined,
              },
            },
          },
        };
      });
    },
    setError: (nodeId, error) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              isCalculating: false,
              isFetching: false,
              isLoading: false,
              isSuccess: false,
              isError: true,
              error,
              successTime: '',
              failTime: '',
            },
          },
        }),
      }));
    },
    getTestRunState: (nodeId) => {
      return get().testRunSlice.nodes[nodeId];
    },
    getGroupTestRunState: (nodeIds) => {
      const state: { [key: string]: TestRunState | undefined } = {
        failed: undefined,
        succeed: undefined,
      };
      if (!nodeIds) return state.succeed;
      nodeIds.forEach((nodeId) => {
        const nodeState = get().testRunSlice.nodes[nodeId];
        if (nodeState?.failTime) {
          state.failed = nodeState;
          return;
        }
        if (nodeState?.successTime) {
          state.succeed = nodeState;
        }
      });

      if (state.failed) return state.failed;
      return state.succeed;
    },
    getTestRunStateAllNodes: (nodeIds) => {
      return nodeIds.map((nodeId) => {
        const testRunState = get().testRunSlice.nodes[nodeId];
        return { ...testRunState, nodeId };
      });
    },
    resetFetching: (nodeId) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              ...(state.testRunSlice.getTestRunState(nodeId) || {}),
              isFetching: false,
            },
          },
        }),
      }));
    },
    resetLoading: (nodeId) => {
      set((state) => {
        const stateLoading = state.testRunSlice.getTestRunState(nodeId) || {};
        return {
          testRunSlice: _.merge({}, state.testRunSlice, {
            nodes: {
              [nodeId]: {
                ...stateLoading,
                isLoading: false,
              },
            },
          }),
        };
      });
    },
    resetCalculating: (nodeId, isSimulationNeeded) => {
      set((state) => {
        const stateLoading = state.testRunSlice.getTestRunState(nodeId) || {};
        return {
          testRunSlice: _.merge({}, state.testRunSlice, {
            nodes: {
              [nodeId]: {
                ...stateLoading,
                isError: false,
                error: undefined,
                isCalculating: false,
                calculatingFieldNames: undefined,
                isSimulationNeeded,
              },
            },
          }),
        };
      });
    },
    reset: (nodeId) => {
      set((state) => ({
        testRunSlice: _.merge({}, state.testRunSlice, {
          nodes: {
            [nodeId]: {
              isCalculating: false,
              isFetching: false,
              isLoading: false,
              isSuccess: false,
              isError: false,
              error: '',
              calculatingFieldNames: undefined,
              isSimulationNeeded: false,
              successTime: undefined,
              failTime: undefined,
            },
          },
        }),
      }));
    },
    resetAll: () => {
      const nodeWithCleanOutputValues = get().nodes.map((node) => {
        if (!isPluginNode(node)) return node;
        return {
          ...node,
          data: {
            ...node.data,
            outputList: node.data.outputList.map((output) => ({
              ...output,
              value: undefined,
              calculatingFieldNames: undefined,
              isSimulationNeeded: false,
            })),
          },
        };
      });

      set((state) => ({
        nodes: nodeWithCleanOutputValues,
        testRunSlice: {
          ...state.testRunSlice,
          nodes: {},
        },
      }));
    },
  },
});
