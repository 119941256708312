import { service } from '@kiroboio/fct-sdk';
import type { BigNumberish } from 'ethers';
import BigDecimal from 'bignumber.js';

export const MAX_UINT_256 =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const MAX_UINT_96 = '79228162514264337593543950336';
export const weiToEther = (value?: BigNumberish, decimals = 18) => {
  if (!value) return undefined;
  BigDecimal.config({
    DECIMAL_PLACES: decimals,
    ROUNDING_MODE: BigDecimal.ROUND_FLOOR,
  });
  try {
    const ethValue = new BigDecimal(value.toString())
      .div(new BigDecimal(`1${'0'.repeat(decimals)}`))
      .multipliedBy(new BigDecimal('1'));

    const decimalsPlaces = ethValue.decimalPlaces();
    if (
      decimalsPlaces &&
      decimalsPlaces > 6 &&
      ethValue.lt(new BigDecimal('0.000001'))
    ) {
      // remove scientific notation eg:(1+18) for small numbers
      return ethValue.toFormat(decimals);
    }

    return ethValue.toString();
  } catch (e) {
    console.error({ ethValueError: e });
    return '0';
  }
};

export const formatNumber = (value: number, decimals = 18) => {
  BigDecimal.config({
    DECIMAL_PLACES: decimals,
    ROUNDING_MODE: BigDecimal.ROUND_FLOOR,
  });
  try {
    const ethValue = new BigDecimal(value);

    return new BigDecimal(ethValue.toFormat(decimals))
      .multipliedBy(new BigDecimal('1'))
      .toString();
  } catch (e) {
    console.error({ ethValueError: e });
    return '0';
  }
};
export const etherToWei = (value: string, decimals = 18) => {
  BigDecimal.config({
    DECIMAL_PLACES: decimals,
    ROUNDING_MODE: BigDecimal.ROUND_FLOOR,
  });
  return new BigDecimal(value)
    .multipliedBy(new BigDecimal(`1${'0'.repeat(decimals)}`))
    .toFixed(0);
};

interface ToMaxFixedOpts extends Intl.NumberFormatOptions {
  locale: string;
}

export const replaceLastNumberRegex = /\d+$/;
export const getLengthFromArrayType = (value: string) => {
  const parsedValue = value.match(/\[[^\]]*\]/);
  if (!parsedValue || !parsedValue.length) return undefined;
  const amount = parsedValue[0].match(/\d+/);
  return amount && amount.length ? Number(amount[0]) : undefined;
};
export const toMaxFixed = (
  number: number,
  decimals = 2,
  { locale, ...options }: Partial<ToMaxFixedOpts> = { locale: 'en-US' }
): string => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
    useGrouping: false,
    ...options,
  }).format(number);
};

interface FormatUsdOpts extends Intl.NumberFormatOptions {
  locale?: Parameters<typeof Intl.NumberFormat>[0];
}
export const formatUsd = (
  number: number | string,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    locale = 'en-US',
    ...options
  }: FormatUsdOpts = {}
): string => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping: true,
    ...options,
  }).format(+number);
};

export const formatUsdService = (number: number | string): string => {
  return service.formatting.prebuild.formatValue({
    service: 'dollar',
    name: 'price',
    value: number,
    decimals: 0,
    digits: 2,
    format: '0,0.00',
  });
};

interface FormatWeiOutputOptions {
  maxFixed?: number;
  decimals?: number;
  useGrouping?: boolean;
}

export const formatWeiOutput = (
  value?: string,
  {
    maxFixed = 6,
    decimals = 18,
    useGrouping = true,
  }: FormatWeiOutputOptions = {}
): string => {
  if (!value) return '';
  const ethers = weiToEther(value, decimals ?? 18);
  if (!ethers) return '';
  return toMaxFixed(parseFloat(ethers), maxFixed, { useGrouping });
};

export const abbreviateNumber = (num: number): string => {
  if (num < 1000) return num.toString(); // Return the same number if it's less than 1000

  try {
    const units = ['K', 'M', 'B', 'T'];
    const exponent = Math.floor(Math.log(num) / Math.log(1000));
    const baseValue = num / 1000 ** exponent;
    const precisionValue =
      baseValue >= 10
        ? Math.round(baseValue)
        : parseFloat(baseValue.toFixed(1));

    return `${precisionValue}${units[exponent - 1]}`;
  } catch (e) {
    console.error(e);
    return '0';
  }
};
