/* eslint-disable import/no-cycle */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useNodeId, useStore } from '@kiroboio/reactflow';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import type { ApproveSettingsSlice } from './approveSettings';
import { createApproveSettingsSlice } from './approveSettings';
import type { DiagramSettingsSlice } from './diagramSettings';
import { createDiagramSettingsSlice } from './diagramSettings';
import type { FlowSlice } from './flows';
import { createFlowSlice } from './flows';
import type { GroupSlice } from './group';
import { createGroupSlice } from './group';
import type { FctEdge, MainSlice } from './main';
import { createMainSlice } from './main';
import type { NodesStateSlice } from './nodeState';
import { createNodesStateSlice } from './nodeState';
import type { InputSlice } from './parameters';
import { createInputSlice } from './parameters';
import type { PluginsSlice } from './plugins';
import { createPluginsSlice } from './plugins';
import type { PointersSlice } from './pointers';
import { createPointersSlice } from './pointers';
import type { ReactFlowMirror } from './reactFlowMirror';
import { createReactFlowMirror } from './reactFlowMirror';
import type { TestRunSlice } from './testrun';
import { createTestRunSlice } from './testrun';
import type { ValidatorSlice } from './validators';
import { createValidatorSlice } from './validators';

export type StoreState = MainSlice &
  InputSlice &
  PluginsSlice &
  PointersSlice &
  DiagramSettingsSlice &
  // InstructionsSlice &
  GroupSlice &
  ValidatorSlice &
  NodesStateSlice &
  TestRunSlice &
  FlowSlice &
  ReactFlowMirror &
  ApproveSettingsSlice & { reset: () => void };

export type StoreSlice<T> = StateCreator<StoreState, [], [], T>;

const createStore = create(
  subscribeWithSelector<StoreState>((set, get, store) => {
    const init = {
      ...createMainSlice(set, get, store),
      ...createInputSlice(set, get, store),
      ...createPluginsSlice(set, get, store),
      ...createPointersSlice(set, get, store),
      ...createDiagramSettingsSlice(set, get, store),
      // ...createInputInstructionsSlice(set, get, store),
      ...createGroupSlice(set, get, store),
      ...createValidatorSlice(set, get, store),
      ...createNodesStateSlice(set, get, store),

      ...createTestRunSlice(set, get, store),
      ...createFlowSlice(set, get, store),
      ...createApproveSettingsSlice(set, get, store),
      ...createReactFlowMirror(set, get, store),
    };
    return {
      ...init,
      reset: () => {
        get().intentGraph = undefined;
        get().pointersSlice.clearAllValues();
        const initState = store.getInitialState();
        set(initState);
      },
    };
  })
);

export const useFCTStore = createStore;
export const useMockFCTStore = createStore;

// create pointers
useFCTStore.subscribe(
  (state) => state.edges.length,
  () => {
    useFCTStore.getState().pointersSlice.createPointers();
    useFCTStore.getState().pointersSlice.createMarkers();
  }
);

// create nodeStates and group flow handles
useFCTStore.subscribe(
  (state) => [
    Boolean(state.intentGraph),
    state.nodes?.map((n) => n.id).join(''),
    state.edges?.map((e) => e.id).join(''),
  ],
  () => {
    const state = useFCTStore.getState();
    // state.createGroupedNodeIds();
    // state.nodeStates.createStates();
    // state.flowSlice.createFlows();
    if (!state.intentGraph) return;
    state.generateGraph();
    state.createGroupDetails();
    // useFCTStore.getState().testRunSlice.resetAll();
  },
  {
    equalityFn: shallow,
  }
);

// create input instructions
useFCTStore.subscribe(
  (state) => state.nodes?.length,
  () => {
    useFCTStore.getState().setPlugins();
    // useFCTStore.getState().instructionsSlice.createInstructions();
    useFCTStore.getState().createParametersMap();
  }
);

export const getEdgeKey = (edge: FctEdge) =>
  `${edge.source}.${edge.sourceHandle}.${edge.target}.${edge.targetHandle}`;

useFCTStore.subscribe(
  (state) => [state.selectedGroup],
  () => {
    const state = useFCTStore.getState();
    state.createDisplayEdges();
  },
  {
    equalityFn: shallow,
  }
);

// create input instructions
useFCTStore.subscribe(
  (state) => [state.nodes?.length, state.selectedGroup],
  () => {
    useFCTStore.getState().updateDisplayNodes();
  },
  {
    equalityFn: shallow,
  }
);

if (typeof window !== 'undefined') {
  // @ts-expect-error asdasd
  window.useFCTStore = useFCTStore;
}

export const updateNode = (id: string) => {
  useFCTStore.getState().updateParametersMap(id);
  // useFCTStore.getState().instructionsSlice.createInstructions();
  useFCTStore.getState().nodeStates.createStates();
};

export const updateNodes = (ids: string[]) => {
  ids.forEach(useFCTStore.getState().updateParametersMap);
  // useFCTStore.getState().instructionsSlice.createInstructions();
  useFCTStore.getState().nodeStates.createStates();
};

export const useNodeData = (nodeIdIn?: string) => {
  const contextId = useNodeId();
  const nodeId = nodeIdIn || contextId;
  return useFCTStore(
    (state) => (nodeId ? state.getNodeData(nodeId) : undefined),
    shallow
  );
};

export const useParameter = (path: string, nodeId?: string) => {
  const contextNodeId = useNodeId();
  const nodeIdIn = nodeId || contextNodeId || '';
  return useFCTStore.getState().getParameter(nodeIdIn, path);
};

export const useNodeState = () => {
  const nodeId = useNodeId();
  return useFCTStore(
    (state) => (nodeId ? state.getNodeState(nodeId) : undefined),
    shallow
  );
};

export const useConnection = () => {
  return useStore(
    (state) =>
      state.connectionNodeId
        ? {
            nodeId: state.connectionNodeId,
            handleId: state.connectionHandleId,
            handleType: state.connectionHandleType,
          }
        : undefined,
    shallow
  );
};
