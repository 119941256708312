import type { StoreSlice } from '.';

export type ApproveSettingsState = {
  isEnabled: boolean;
};

export type ApproveSettingsSlice = {
  approveSettingsSlice: {
    isEnabled: boolean;
    getIsEnabled: () => boolean;
    setIsEnabled: (isEnabled: boolean) => void;
  };
};

export const createApproveSettingsSlice: StoreSlice<ApproveSettingsSlice> = (
  set,
  get
) => ({
  approveSettingsSlice: {
    isEnabled: false,
    setIsEnabled: (isEnabled: boolean) => {
      set((state) => {
        return {
          approveSettingsSlice: { ...state.approveSettingsSlice, isEnabled },
        };
      });
    },

    getIsEnabled: () => {
      return get().approveSettingsSlice.isEnabled;
    },
  },
});
